import { inject, injectable } from 'inversify'
import { ReportRepositoryInterface, Repository } from '~/abstracts/repository'
import { GQLManagerLog, GQLManagerLogsFilter, GQLQuery } from '~/types/gql'
import { ReportManagerLogs } from '~/features/report/manager-logs/report-manager-logs.interface'
import DiTypes from '~/config/DiTypes'
import { IReportManagerLogsFactory } from '~/abstracts/factory'
import { Apollo } from '~/api/GraphqlClient'

@injectable()
export class ReportRepository extends Repository
  implements ReportRepositoryInterface {
  private managerLogsFactory: IReportManagerLogsFactory

  constructor(
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo,
    @inject(DiTypes.REPORT_MANAGER_LOGS_FACTORY)
    managerLogsFactory: IReportManagerLogsFactory
  ) {
    super(apollo)
    this.managerLogsFactory = managerLogsFactory
  }

  public async getManagerLogs(
    filter: GQLManagerLogsFilter
  ): Promise<ReportManagerLogs> {
    const { data } = await this.apollo.client.query<
      {
        ManagerLogs: GQLQuery['ManagerLogs']
      },
      { filter: GQLManagerLogsFilter }
    >({
      query: await import('~/graphql/report/manager-logs.graphql'),
      variables: {
        filter,
      },
    })

    if (!data.ManagerLogs)
      throw new Error('Ошибка получения списка действий менеджера')

    return this.managerLogsFactory.create(data.ManagerLogs as GQLManagerLog[])
  }
}
