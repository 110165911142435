export default {
  GRAPHQL_CLIENT: Symbol('GraphqlClient'),

  LOGGER: Symbol('Logger'),
  APP_LOGGER: Symbol('AppLogger'),
  APP_LOCAL_LOGGER: Symbol('AppLocalLogger'),

  PRODUCT_REPOSITORY: Symbol('ProductRepository'),
  PRODUCT_PROPERTIES_REPOSITORY: Symbol('ProductPropertiesRepository'),
  PRODUCT_FORM_FACTORY: Symbol('ProductFormFactory'),
  PRODUCT_LIST_FACTORY: Symbol('ProductListFactory'),
  PRODUCT_INPUT_FACTORY: Symbol('ProductInputFactory'),
  PRODUCT_FILTER_FACTORY: Symbol('ProductFilterFactory'),
  PRODUCT_FILTER_INPUT_FACTORY: Symbol('ProductFilterInputFactory'),

  CATEGORY_REPOSITORY: Symbol('CategoryRepository'),
  CATEGORY_LIST_ITEM_FACTORY: Symbol('CategoryListItemFactory'),
  CATEGORY_TREE_FACTORY: Symbol('CategoryTreeFactory'),
  CATEGORY_TREE_SELECT_FACTORY: Symbol('CategoryTreeSelectFactory'),
  CATEGORY_TREE_INPUT_FACTORY: Symbol('CategoryTreeInputFactory'),
  CATEGORY_INPUT_FACTORY: Symbol('CategoryInputFactory'),
  CATEGORY_FORM_FACTORY: Symbol('CategoryFormFactory'),
  CATEGORY_PRODUCT_FACTORY: Symbol('CategoryProductFactory'),

  ORDER_REPOSITORY: Symbol('OrderRepository'),
  ORDER_LIST_FACTORY: Symbol('OrderListFactory'),
  ORDER_FORM_FACTORY: Symbol('OrderFormFactory'),
  ORDER_INPUT_FACTORY: Symbol('OrderInputFactory'),

  USER_REPOSITORY: Symbol('UserRepository'),

  MANAGER_LIST_FACTORY: Symbol('ManagerListFactory'),
  MANAGER_INPUT_FACTORY: Symbol('ManagerInputFactory'),
  MANAGER_FORM_FACTORY: Symbol('ManagerFormFactory'),

  REPORT_REPOSITORY: Symbol('ReportRepository'),
  REPORT_MANAGER_LOGS_FACTORY: Symbol('ReportManagerLogsFactory'),
  REPORT_MANAGER_LOGS_INPUT_FACTORY: Symbol('ReportManagerLogsInputFactory'),

  SEARCH_REPOSITORY: Symbol('SearchRepository'),

  BANNER_REPOSITORY: Symbol('BannerRepository'),
  BANNER_INPUT_FACTORY: Symbol('BannerInputFactory'),
  BANNER_FACTORY: Symbol('BannerFactory'),

  BRAND_REPOSITORY: Symbol('BrandRepository'),

  IMAGE_REPOSITORY: Symbol('ImageRepository'),
}
