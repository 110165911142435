import 'reflect-metadata'
// eslint-disable-next-line import/named
import { Container, interfaces } from 'inversify'
import DiTypes from './DiTypes'
import { Apollo, GraphqlClientInterface } from '~/api/GraphqlClient'
import {
  ProductFormFactory,
  ProductListFactory,
} from '~/features/product/factory'

import {
  CategoryRepositoryInterface,
  IBannerRepository,
  IBrandRepository,
  ISearchRepository,
  OrderRepositoryInterface,
  ProductPropertiesRepositoryInterface,
  ProductRepositoryInterface,
  ReportRepositoryInterface,
  UserRepositoryInterface,
} from '~/abstracts/repository'
import { ProductRepository } from '~/repositories/product'
import {
  CategoryFormFactoryInterface,
  CategoryInputFactoryInterface,
  CategoryListItemFactoryInterface,
  CategoryProductFactoryInterface,
  CategoryTreeFactoryInterface,
  CategoryTreeInputFactoryInterface,
  IBannerFactory,
  IBannerInputFactory,
  IManagerFormFactory,
  IManagerInputFactory,
  IManagerListFactory,
  IProductFilterFactory,
  IReportManagerLogsFactory,
  IReportManagerLogsInputFactory,
  OrderFormFactoryInterface,
  OrderInputFactoryInterface,
  OrderListFactoryInterface,
  ProductFilterInputFactoryInterface,
  ProductFormFactoryInterface,
  ProductInputFactoryInterface,
  ProductListItemFactoryInterface,
} from '~/abstracts/factory'
import { ProductInputFactory } from '~/features/product/factory/product-input-factory'
import { CategoryRepository } from '~/repositories/category'
import { CategoryListFactory } from '~/features/category/factory/category-list-factory'
import { CategoryFormFactory } from '~/features/category/factory/category-form-factory'
import { CategoryInputFactory } from '~/features/category/factory/category-input-factory'
import { CategoryTreeFactory } from '~/features/category/factory/category-tree-factory'
import { OrderListFactory } from '~/features/order/factory/order-list-factory'
import { OrderRepository } from '~/repositories/order'
import { CategoryTreeInputFactory } from '~/features/category/factory/category-tree-input-factory'
import { TreeSelectNodeFactory } from '~/features/category-tree-select/tree-select-node-factory'
import { TreeSelectNodeFactoryInterface } from '~/features/category-tree-select/types'
import { ProductPropertiesRepository } from '~/repositories/product-properties'
import { OrderFormFactory } from '~/features/order/factory/order-form-factory'
import { OrderInputFactory } from '~/features/order/factory/order-input-factory'
import { UserRepository } from '~/repositories/user'
import { ProductFilterInputFactory } from '~/features/product/factory/product-filter-input.factory'
import { AppLocalLogger, AppLogger, ILogger } from '~/app/logger'
import { CategoryProductFactory } from '~/features/category-product'
import { ManagerInputFactory } from '~/features/manager/manager-form-input/manager-form-input.factory'
import { ManagerListFactory } from '~/features/manager/manager-list'
import { ManagerFormFactory } from '~/features/manager/manager-form/manager-form.factory'
import { ReportRepository } from '~/repositories/report'
import { ReportManagerLogsFactory } from '~/features/report/manager-logs/report-manager-logs.factory'
import { ReportManagerLogsInputFactory } from '~/features/report/manager-logs/report-manager-logs-input.factory'
import { SearchRepository } from '~/repositories/search'
import { PickupOrderInputFactory } from '~/features/order/factory/pickup-order-input-factory'
import { ProductFilterFactory } from '~/features/product/factory/product-filter-factory'
import { BannerRepository } from '~/repositories/banner'
import { BannerInputFactory } from '~/features/banner/banner-input.factory'
import { BannerFactory } from '~/features/banner/banner.factory'
import { BrandRepository } from '~/repositories/brand'
import { IImageRepository, ImageRepository } from '~/repositories/image'

const container: Container = new Container()

container
  .bind<GraphqlClientInterface>(DiTypes.GRAPHQL_CLIENT)
  .toConstantValue(new Apollo())

container
  .bind<ProductRepositoryInterface>(DiTypes.PRODUCT_REPOSITORY)
  .to(ProductRepository)
  .inSingletonScope()

container.bind<ILogger>(DiTypes.APP_LOGGER).to(AppLogger).inSingletonScope()

container
  .bind<ILogger>(DiTypes.APP_LOCAL_LOGGER)
  .to(AppLocalLogger)
  .inSingletonScope()

container
  .bind<ILogger>(DiTypes.LOGGER)
  .toDynamicValue((context: interfaces.Context) => {
    return process.env.NODE_ENV === 'production'
      ? context.container.get<ILogger>(DiTypes.APP_LOGGER)
      : context.container.get<ILogger>(DiTypes.APP_LOCAL_LOGGER)
  })

container
  .bind<ProductPropertiesRepositoryInterface>(
    DiTypes.PRODUCT_PROPERTIES_REPOSITORY
  )
  .to(ProductPropertiesRepository)
  .inSingletonScope()

container
  .bind<ProductFormFactoryInterface>(DiTypes.PRODUCT_FORM_FACTORY)
  .to(ProductFormFactory)
  .inSingletonScope()

container
  .bind<ProductInputFactoryInterface>(DiTypes.PRODUCT_INPUT_FACTORY)
  .to(ProductInputFactory)
  .inSingletonScope()

container
  .bind<ProductListItemFactoryInterface>(DiTypes.PRODUCT_LIST_FACTORY)
  .to(ProductListFactory)
  .inSingletonScope()

container
  .bind<CategoryRepositoryInterface>(DiTypes.CATEGORY_REPOSITORY)
  .to(CategoryRepository)
  .inSingletonScope()

container
  .bind<CategoryListItemFactoryInterface>(DiTypes.CATEGORY_LIST_ITEM_FACTORY)
  .to(CategoryListFactory)
  .inSingletonScope()

container
  .bind<CategoryInputFactoryInterface>(DiTypes.CATEGORY_INPUT_FACTORY)
  .to(CategoryInputFactory)
  .inSingletonScope()

container
  .bind<CategoryFormFactoryInterface>(DiTypes.CATEGORY_FORM_FACTORY)
  .to(CategoryFormFactory)
  .inSingletonScope()

container
  .bind<CategoryTreeFactoryInterface>(DiTypes.CATEGORY_TREE_FACTORY)
  .to(CategoryTreeFactory)
  .inSingletonScope()

container
  .bind<TreeSelectNodeFactoryInterface>(DiTypes.CATEGORY_TREE_SELECT_FACTORY)
  .to(TreeSelectNodeFactory)
  .inSingletonScope()

container
  .bind<CategoryTreeInputFactoryInterface>(DiTypes.CATEGORY_TREE_INPUT_FACTORY)
  .to(CategoryTreeInputFactory)
  .inSingletonScope()

container
  .bind<OrderRepositoryInterface>(DiTypes.ORDER_REPOSITORY)
  .to(OrderRepository)
  .inSingletonScope()

container
  .bind<OrderListFactoryInterface>(DiTypes.ORDER_LIST_FACTORY)
  .toConstantValue(new OrderListFactory())

container
  .bind<OrderFormFactoryInterface>(DiTypes.ORDER_FORM_FACTORY)
  .toConstantValue(new OrderFormFactory())

container
  .bind<OrderInputFactoryInterface>(DiTypes.ORDER_INPUT_FACTORY)
  .to(OrderInputFactory)
  .whenTargetTagged('delivery', true)

container
  .bind<OrderInputFactoryInterface>(DiTypes.ORDER_INPUT_FACTORY)
  .to(PickupOrderInputFactory)
  .whenTargetTagged('delivery', false)

container
  .bind<UserRepositoryInterface>(DiTypes.USER_REPOSITORY)
  .to(UserRepository)
  .inSingletonScope()

container
  .bind<ProductFilterInputFactoryInterface>(
    DiTypes.PRODUCT_FILTER_INPUT_FACTORY
  )
  .toConstantValue(new ProductFilterInputFactory())

container
  .bind<IProductFilterFactory>(DiTypes.PRODUCT_FILTER_FACTORY)
  .toConstantValue(new ProductFilterFactory())

container
  .bind<CategoryProductFactoryInterface>(DiTypes.CATEGORY_PRODUCT_FACTORY)
  .to(CategoryProductFactory)

container
  .bind<IManagerListFactory>(DiTypes.MANAGER_LIST_FACTORY)
  .to(ManagerListFactory)

container
  .bind<IManagerInputFactory>(DiTypes.MANAGER_INPUT_FACTORY)
  .to(ManagerInputFactory)

container
  .bind<IManagerFormFactory>(DiTypes.MANAGER_FORM_FACTORY)
  .to(ManagerFormFactory)

container
  .bind<ReportRepositoryInterface>(DiTypes.REPORT_REPOSITORY)
  .to(ReportRepository)

container
  .bind<IReportManagerLogsFactory>(DiTypes.REPORT_MANAGER_LOGS_FACTORY)
  .to(ReportManagerLogsFactory)

container
  .bind<IReportManagerLogsInputFactory>(
    DiTypes.REPORT_MANAGER_LOGS_INPUT_FACTORY
  )
  .to(ReportManagerLogsInputFactory)

container
  .bind<ISearchRepository>(DiTypes.SEARCH_REPOSITORY)
  .to(SearchRepository)

container
  .bind<IBannerRepository>(DiTypes.BANNER_REPOSITORY)
  .to(BannerRepository)

container
  .bind<IBannerInputFactory>(DiTypes.BANNER_INPUT_FACTORY)
  .to(BannerInputFactory)

container.bind<IBannerFactory>(DiTypes.BANNER_FACTORY).to(BannerFactory)

container.bind<IBrandRepository>(DiTypes.BRAND_REPOSITORY).to(BrandRepository)

container.bind<IImageRepository>(DiTypes.IMAGE_REPOSITORY).to(ImageRepository)

export { container }
