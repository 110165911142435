import { injectable } from 'inversify'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'

import { errorLink } from './links/error'
import { GraphqlClientInterface } from './types'
import { getAuthLink } from './links/auth'

const getHttpLink = (uri: string) =>
  createHttpLink({
    uri,
    headers: { 'X-App-Name': process.env.applicationName },
  })
const authLink = getAuthLink()
const cache = new InMemoryCache()

const createClient = () => {
  if (process.env.graphQLEndpoint === undefined) {
    throw new Error('GraphQL endpoint is undefined')
  }
  const httpLink = getHttpLink(process.env.graphQLEndpoint)
  const apolloLinks = ApolloLink.from([errorLink, authLink, httpLink])

  return new ApolloClient({
    link: apolloLinks,
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}

@injectable()
class Apollo implements GraphqlClientInterface {
  public client: ApolloClient<any>

  constructor() {
    this.client = createClient()
  }
}

export { Apollo }
