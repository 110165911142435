import { useStore } from 'vuex-simple'
import { Component, VueComponent, VNode } from '~/types/vue-ts-component'
import { BNavbar, BNavbarItem } from '~/components/UI/buefy'
import { redirectToLogin } from '~/utils/redirects'
import { RootModule } from '~/store/root'
import { getNavLinks, NavLink } from '~/components/layout/navbar/navbar.config'

@Component
export class AppNavbar extends VueComponent {
  authModule = useStore<RootModule>(this.$store).authentication

  get navbarItems(): NavLink[] {
    return getNavLinks(this.authModule.userRoles)
  }

  handleLogout(): void {
    this.authModule.logout()
    redirectToLogin(this.$router.push.bind(this.$router))
  }

  render(): VNode {
    return (
      <BNavbar shadow>
        <BNavbarItem slot="brand" tag="nuxt-link" to={{ path: '/' }}>
          <img src={require('~/assets/icons/logo.svg')} />
        </BNavbarItem>
        <template slot="start">
          {this.navbarItems.map((navItem) => (
            <BNavbarItem key={navItem.href} href={navItem.href}>
              {navItem.title}
            </BNavbarItem>
          ))}
        </template>
        <template slot="end">
          <BNavbarItem tag="div">
            <a class="button is-light" onClick={this.handleLogout}>
              Выйти
            </a>
          </BNavbarItem>
        </template>
      </BNavbar>
    )
  }
}
