import { injectable } from 'inversify'
import {
  ISearchRepository,
  PaginatedResponse,
  Repository,
} from '~/abstracts/repository'
import {
  GQLQuery,
  GQLSearchHistoryType,
  GQLSearchQueryGroupedResult,
  GQLSearchQueryTimelineResult,
} from '~/types/gql'
import {
  SearchQueryTimeline,
  SearchQueryGrouped,
} from '~/features/search/search-query/search-query.interface'
import {
  searchQueryGroupedMapper,
  searchQueryTimelineMapper,
} from '~/features/search/search-query/search-query.mapper'

@injectable()
export class SearchRepository extends Repository implements ISearchRepository {
  public async getSearchHistory(
    first: number,
    page: number
  ): Promise<PaginatedResponse<SearchQueryGrouped>> {
    const { data } = await this.apollo.client.query<
      { SearchHistory: GQLQuery['SearchHistory'] },
      { first: number; page: number }
    >({
      query: await import('~/graphql/search/search-history.graphql'),
      variables: {
        first,
        page,
      },
    })

    return {
      totalItems: data.SearchHistory.paginatorInfo.total,
      totalPages: data.SearchHistory.paginatorInfo.lastPage,
      items: (data.SearchHistory.data as GQLSearchQueryGroupedResult[]).map(
        searchQueryGroupedMapper
      ),
    }
  }

  public async getSearchHistoryTimeline(
    first: number,
    page: number
  ): Promise<PaginatedResponse<SearchQueryTimeline>> {
    const { data } = await this.apollo.client.query<
      { SearchHistory: GQLQuery['SearchHistory'] },
      { first: number; page: number; historyType: GQLSearchHistoryType }
    >({
      query: await import('~/graphql/search/search-history.graphql'),
      variables: {
        historyType: GQLSearchHistoryType.Timeline,
        first,
        page,
      },
    })

    return {
      totalItems: data.SearchHistory.paginatorInfo.total,
      totalPages: data.SearchHistory.paginatorInfo.lastPage,
      items: (data.SearchHistory.data as GQLSearchQueryTimelineResult[]).map(
        searchQueryTimelineMapper
      ),
    }
  }
}
