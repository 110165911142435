import { GraphQLErrorStructure } from '~/app/validation-error/interface'

const GQL_ERROR_ENTITY_REGEXP = /input\.(?<entity>\w+)(\.(?<index>\d+))?(\.(?<subfield>\w+))?/

type ParseErrorEntity = {
  entity?: string
  index?: number
  subfield?: string
}

export const parseErrorEntity = (
  gqlEntity: GraphQLErrorStructure['entity']
): ParseErrorEntity => {
  const matcher = gqlEntity.match(GQL_ERROR_ENTITY_REGEXP)
  return {
    entity: matcher?.groups?.entity,
    index: matcher?.groups?.index ? Number(matcher.groups.index) : undefined,
    subfield: matcher?.groups?.subfield,
  }
}
