import Vue from 'vue'
import { ILogger } from '~/app/logger'

type CSSClass =
  | string
  | false
  | {
      [key: string]: string
    }

export class VueComponent<P = {}> extends Vue {
  // @ts-ignore
  public $props: P & {
    key?: string | number
    class?: CSSClass | CSSClass[]
    click?: (e: Event) => void
    slot?: string
    ref?: string
  }

  public $loading: any

  public $logger!: ILogger
}

export type { VNode } from 'vue'
export {
  Component,
  Prop,
  Watch,
  Emit,
  Mixins,
  Inject,
  Provide,
  Ref,
} from 'vue-property-decorator'
