import { injectable } from 'inversify'
import { ProductFormFactoryInterface } from '~/abstracts/factory'
import { GQLCategory, GQLProduct, GQLGalleryImage } from '~/types/gql'
import { centsToPrice } from '~/utils/format-price'
import {
  ProductCategoriesFormType,
  ProductFormType,
  ProductImageFormType,
} from '~/features/product/types'
import { SeoFormType } from '~/features/seo/types'
import { cdnLink, getSmallImage } from '~/utils/get-image-url'
import { formatIsStockUpdate } from '~/utils/format-date'

@injectable()
export class ProductFormFactory implements ProductFormFactoryInterface {
  public create(gqlProduct: GQLProduct): ProductFormType {
    const categoryId = gqlProduct.category
      ? Number(gqlProduct.category.id)
      : null

    return {
      id: Number(gqlProduct.id),
      name: gqlProduct.name,
      sku: gqlProduct.sku,
      slug: gqlProduct.slug,
      description: gqlProduct.description,
      instruction: gqlProduct.instruction,
      price: String(centsToPrice(gqlProduct.price)),
      oldPrice: String(centsToPrice(gqlProduct.oldPrice)),
      isActive: gqlProduct.isActive,
      images: this.createImages(gqlProduct.images),
      categories: this.createCategories(gqlProduct.categories, categoryId),
      seo: this.getProductSeo(gqlProduct.seo),
      country: gqlProduct.country?.name,
      manufacturer: gqlProduct.manufacturer?.name,
      ingredient: gqlProduct.ingredient?.name,
      brand: gqlProduct.brand?.name,
      inStock: gqlProduct.inStock,
      inStockUpdate: gqlProduct.inStockUpdate
        ? formatIsStockUpdate(gqlProduct.inStockUpdate)
        : null,
      isStockSync: gqlProduct.isStockSync ?? false,
      searchWords: gqlProduct.searchWords,
    }
  }

  private createImages(images: GQLGalleryImage[]): ProductImageFormType[] {
    return images.map(this.createImage)
  }

  private createImage({
    image,
    sortOrder,
  }: GQLGalleryImage): ProductImageFormType {
    return {
      id: Number(image.id),
      title: image.title,
      sortOrder,
      src: cdnLink(getSmallImage(image)),
    }
  }

  private getProductSeo(seo: GQLProduct['seo']): SeoFormType {
    return {
      title: seo.title,
      keywords: seo.keywords,
      description: seo.description,
    }
  }

  private createCategories(
    categories: GQLCategory[],
    categoryId: number | null
  ): ProductCategoriesFormType[] {
    return categories.map((category) => {
      const main = categoryId === Number(category.id)
      return this.createCategory(category, main)
    })
  }

  private createCategory(
    category: GQLCategory,
    main: boolean
  ): ProductCategoriesFormType {
    return {
      id: Number(category.id),
      name: category.name,
      main,
    }
  }
}
