import { injectable } from 'inversify'
import { Factory, IReportManagerLogsFactory } from '~/abstracts/factory'
import { GQLManagerLog } from '~/types/gql'
import {
  ReportManagerLogItem,
  ReportManagerLogs,
} from '~/features/report/manager-logs/report-manager-logs.interface'
import { formatDate } from '~/utils/format-date'

@injectable()
export class ReportManagerLogsFactory
  extends Factory<GQLManagerLog[], ReportManagerLogs>
  implements IReportManagerLogsFactory {
  public create(input: GQLManagerLog[]): ReportManagerLogs {
    return input.reduce<ReportManagerLogs>((acc, logItem) => {
      const dictItem = ReportManagerLogsFactory.createItem(logItem)
      const timestamp = formatDate(logItem.createdAt)

      return {
        ...acc,
        [timestamp]: acc[timestamp]?.length
          ? [...acc[timestamp], dictItem]
          : [dictItem],
      }
    }, {})
  }

  static createItem(item: GQLManagerLog): ReportManagerLogItem {
    return {
      productName: item.entity?.name || 'не определено',
      productSlug: item.entity?.slug || '',
      productSku: item.entity?.sku || '',
      userName: item.user.name,
      type: item.type,
      createdAt: item.createdAt,
    }
  }
}
