import { injectable } from 'inversify'
import { ILogger } from '~/app/logger/logger.interface'

@injectable()
export class AppLocalLogger implements ILogger {
  public error(exception: any): void {
    // eslint-disable-next-line no-console
    console.error('Logger error-link:', { exception })
  }

  public log(message: string): void {
    // eslint-disable-next-line no-console
    console.log('Logger log:', { message })
  }

  public withScope() {
    // eslint-disable-next-line no-console
    console.warn('withScope is not available in dev mode')
  }
}
