import { ExpandedGraphQLError, ValidationError } from './interface'
import { parseErrorEntity } from '~/app/validation-error/validation-error.utils'

type NodeInput = {
  entity: string
  message: string
  index?: number
}

const DEFAULT_PARENT_NODE_ERROR_MESSAGE = ''

export class ValidationErrorFactory {
  static create(input: ExpandedGraphQLError): ValidationError[] {
    return input.extensions?.structure.reduce<ValidationError[]>(
      (errors, structureItem) => {
        const { entity, index, subfield } = parseErrorEntity(
          structureItem.entity
        )
        if (!entity) return errors

        const message = structureItem.message

        if (!subfield)
          return [
            ...errors,
            ValidationErrorFactory.createParentNode(
              { entity, index, message },
              errors
            ),
          ]

        let parentNode = ValidationErrorFactory.getParentNode(
          { entity, index },
          errors
        )
        const childNode = ValidationErrorFactory.createChildNode({
          entity: subfield,
          index: undefined,
          message,
        })

        if (!parentNode) {
          parentNode = ValidationErrorFactory.createParentNode(
            { entity, message: DEFAULT_PARENT_NODE_ERROR_MESSAGE, index },
            errors
          )
          parentNode.children = [childNode]
          return [...errors, parentNode]
        }

        parentNode.children = parentNode.children
          ? [...parentNode.children, childNode]
          : [childNode]

        return errors
      },
      []
    )
  }

  static createChildNode({
    entity,
    index,
    message,
  }: NodeInput): ValidationError {
    return {
      entity,
      index,
      message,
    }
  }

  static createParentNode(
    { entity, index, message }: NodeInput,
    errors: ValidationError[]
  ): ValidationError {
    const parentNode = ValidationErrorFactory.getParentNode(
      { entity, index },
      errors
    )

    if (!parentNode) {
      return ValidationErrorFactory.createChildNode({ entity, index, message })
    }

    parentNode.message =
      parentNode.message === DEFAULT_PARENT_NODE_ERROR_MESSAGE
        ? message
        : parentNode.message

    return parentNode
  }

  static getParentNode(
    { entity, index }: Omit<NodeInput, 'message'>,
    errors: ValidationError[]
  ): ValidationError | undefined {
    return errors.find((error) => {
      return error.entity === entity && error.index === index
    })
  }
}
