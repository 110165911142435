import { inject } from 'inversify'
import { Repository, UserRepositoryInterface } from '~/abstracts/repository'
import { GQLManagerInput, GQLMutation, GQLQuery, GQLRole } from '~/types/gql'
import { setToken } from '~/utils/authorization'
import { ManagerList } from '~/features/manager/manager-list'
import { IManagerFormFactory, IManagerListFactory } from '~/abstracts/factory'
import DiTypes from '~/config/DiTypes'
import { Apollo } from '~/api/GraphqlClient'
import { ManagerFormType } from '~/features/manager/manager-form/manager-form.interface'

export class UserRepository extends Repository
  implements UserRepositoryInterface {
  private managerListFactory: IManagerListFactory
  private managerFormFactory: IManagerFormFactory

  constructor(
    @inject(DiTypes.MANAGER_LIST_FACTORY)
    managerListFactory: IManagerListFactory,
    @inject(DiTypes.MANAGER_FORM_FACTORY)
    managerFormFactory: IManagerFormFactory,
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo
  ) {
    super(apollo)
    this.managerListFactory = managerListFactory
    this.managerFormFactory = managerFormFactory
  }

  public async login(email: string, password: string): Promise<boolean> {
    const { data } = await this.apollo.client.mutate<{
      Login: GQLMutation['Login']
    }>({
      mutation: await import('~/graphql/login.graphql'),
      variables: {
        email,
        password,
      },
    })

    if (!data?.Login.token) return false

    setToken(data.Login.token)

    return true
  }

  public async getRoles(): Promise<GQLRole[]> {
    const { data } = await this.apollo.client.query<{ Me: GQLQuery['Me'] }>({
      query: await import('~/graphql/user/user-roles.graphql'),
    })

    return data.Me.roles
  }

  public async getManagers(): Promise<ManagerList[]> {
    const { data } = await this.apollo.client.query<{
      Users: GQLQuery['Users']
    }>({
      query: await import('~/graphql/user/user-managers.graphql'),
    })

    return this.managerListFactory.fromArray(data.Users.data)
  }

  public async getManager(id: string): Promise<ManagerFormType> {
    const { data } = await this.apollo.client.query<
      { User: GQLQuery['User'] },
      { id: string }
    >({
      query: await import('~/graphql/user/user-manager.graphql'),
      variables: {
        id,
      },
    })

    if (!data.User) throw new Error('Not found')

    return this.managerFormFactory.create(data.User)
  }

  public async createManager(input: GQLManagerInput): Promise<boolean> {
    const { data } = await this.apollo.client.mutate<
      { ManagerSave: GQLMutation['ManagerSave'] },
      { input: GQLManagerInput }
    >({
      mutation: await import('~/graphql/user/manager-save.graphql'),
      variables: {
        input,
      },
    })

    return Boolean(data?.ManagerSave.id)
  }

  public async updateManager(input: GQLManagerInput): Promise<ManagerFormType> {
    const { data } = await this.apollo.client.mutate<
      { ManagerSave: GQLMutation['ManagerSave'] },
      { input: GQLManagerInput }
    >({
      mutation: await import('~/graphql/user/user-manager-update.graphql'),
      variables: {
        input,
      },
    })

    if (!data?.ManagerSave)
      throw new Error('Не удалось обновить данные пользователя')

    return this.managerFormFactory.create(data.ManagerSave)
  }

  public async resetManagerPassword(id: string): Promise<boolean> {
    const { data } = await this.apollo.client.mutate<
      { ManagerPasswordReset: GQLMutation['ManagerPasswordReset'] },
      { id: string }
    >({
      mutation: await import(
        '~/graphql/user/user-manager-password-reset.graphql'
      ),
      variables: {
        id,
      },
    })

    return Boolean(data?.ManagerPasswordReset)
  }
}
