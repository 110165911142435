import { injectable } from 'inversify'
import { CategoryListItemFactoryInterface, Factory } from '~/abstracts/factory'
import { GQLCategoryTree } from '~/types/gql'
import { CategoryListItem } from '~/pages/categories'

@injectable()
export class CategoryListFactory
  extends Factory<GQLCategoryTree, CategoryListItem>
  implements CategoryListItemFactoryInterface {
  public create(gqlCategory: GQLCategoryTree): CategoryListItem {
    return {
      id: Number(gqlCategory.id),
      name: gqlCategory.name,
      slug: gqlCategory.slug,
    }
  }

  public createWithAncestorsFromArray(
    gqlCategories: GQLCategoryTree[]
  ): CategoryListItem[] {
    return gqlCategories.map((item) => this.createWithAncestors(item))
  }

  private createWithAncestors(gqlCategory: GQLCategoryTree): CategoryListItem {
    const category = this.create(gqlCategory)
    category.ancestorsPath = this.makeAncestorsPath(
      gqlCategory.ancestors || [],
      category.name
    )
    return category
  }

  private makeAncestorsPath(
    categories: GQLCategoryTree[],
    categorySelf: string
  ): string {
    const path: string[] = []
    for (const category of categories) {
      path.push(category.name)
    }
    path.push(categorySelf)
    return path.join(' / ')
  }
}
