import { Module, State, Mutation } from 'vuex-simple'

import { ApplicationModule } from './modules/application'
import { AuthenticationModule } from '~/store/modules/authentication'
export { useStore } from 'vuex-simple'

export class RootModule {
  @Module()
  public application = new ApplicationModule(this)

  @Module()
  public authentication = new AuthenticationModule()

  @State()
  public version = '1.0.0'

  @Mutation()
  public setVersion(version: string): void {
    this.version = version
  }
}
