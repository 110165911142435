import { Context, Middleware } from '@nuxt/types'
import { useStore } from 'vuex-simple'
import { getToken } from '~/utils/authorization'
import {
  redirectToDefault,
  redirectToLogin,
  allowedToEnter,
  routeNameIsUnprotected,
  getAppRouteByRouteName,
} from '~/utils/redirects'
import { RootModule } from '~/store/root'
import { AppRoute } from '~/config/routers'

const redirectsMiddleware: Middleware = async ({
  route,
  redirect,
  store,
}: Context) => {
  if (routeNameIsUnprotected(route.name)) return

  if (!getToken()) return redirectToLogin(redirect)

  const { authentication: authenticationModule } = useStore<RootModule>(store)

  if (!authenticationModule.hasRole) {
    await authenticationModule.getUserRoles()
  }

  if (!authenticationModule.roleIsAllowed) {
    return redirectToLogin(redirect)
  }

  const shouldRedirectToDefault = (name: string): boolean => {
    return (
      getAppRouteByRouteName(name) === AppRoute.Index ||
      !authenticationModule.userRoles.some((role) => allowedToEnter(name, role))
    )
  }

  if (shouldRedirectToDefault(route.name as string)) {
    return redirectToDefault(redirect)
  }
}

export default redirectsMiddleware
