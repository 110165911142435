import { GQLImage } from '~/types/gql'

export const enum ImageThumb {
  'thumb80' = 'thumb80',
  'thumb160' = 'thumb160',
  'thumb136' = 'thumb136',
  'thumb272' = 'thumb272',
  'thumb300' = 'thumb300',
  'thumb600' = 'thumb600',
  'thumb416' = 'thumb416',
  'thumb832' = 'thumb832',
  'original' = 'original',
}

export const getThumbedImage = (thumb: ImageThumb = ImageThumb.original) => {
  return (image: GQLImage): string => {
    return `${image.path}/${thumb}/${image.name}`
  }
}

export const getDefaultThumbedImage = getThumbedImage()

export const getTinyImage = getThumbedImage(ImageThumb.thumb80)

export const getSmallImage = getThumbedImage(ImageThumb.thumb136)

export const getMediumImage = getThumbedImage(ImageThumb.thumb272)

export const cdnLink = (link: string): string => {
  return process.env.CDNEndpoint + link
}

export const getDefaultImageUrl = (image: GQLImage) =>
  cdnLink(getDefaultThumbedImage(image))
