const middleware = {}

middleware['products'] = require('../middleware/products.ts')
middleware['products'] = middleware['products'].default || middleware['products']

middleware['redirects'] = require('../middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['search-history'] = require('../middleware/search-history.ts')
middleware['search-history'] = middleware['search-history'].default || middleware['search-history']

export default middleware
