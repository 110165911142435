import { Factory, IBannerFactory } from '~/abstracts/factory'
import { GQLBanner } from '~/types/gql'
import { cdnLink } from '~/utils/get-image-url'
import { Banner } from '~/features/banner/banner'

export class BannerFactory extends Factory<GQLBanner, Banner>
  implements IBannerFactory {
  public create(input: GQLBanner): Banner {
    return {
      id: input.id,
      desktopImage: cdnLink(`/${input.desktopImage}`),
      mobileImage: cdnLink(`/${input.mobileImage}`),
      isActive: input.isActive,
      sortOrder: input.sortOrder,
      url: input.url || undefined,
    }
  }
}
