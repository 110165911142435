import { injectable } from 'inversify'
import { Factory, IReportManagerLogsInputFactory } from '~/abstracts/factory'
import { ManagerLogsFilter } from '~/features/report/manager-logs/manager-logs-filter'
import { GQLManagerLogsFilter } from '~/types/gql'
import { getDateInput, getEndOfMonth } from '~/utils/format-date'

@injectable()
export class ReportManagerLogsInputFactory
  extends Factory<ManagerLogsFilter, GQLManagerLogsFilter>
  implements IReportManagerLogsInputFactory {
  public create(input: ManagerLogsFilter): GQLManagerLogsFilter {
    return {
      createdAt: {
        from: getDateInput(input.date),
        to: getDateInput(getEndOfMonth(input.date)),
      },
    }
  }
}
