import { inject, injectable } from 'inversify'

import { Apollo } from '~/api/GraphqlClient'
import DiTypes from '~/config/DiTypes'
import {
  GQLBannerInput,
  GQLBrandInput,
  GQLCategoryFilter,
  GQLCategorySaveInput,
  GQLCategoryTree,
  GQLCategoryTreeSaveInput,
  GQLManagerInput,
  GQLManagerLogsFilter,
  GQLOrderInput,
  GQLOrderPaginator,
  GQLOrderPayment,
  GQLOrderShipping,
  GQLOrderStatus,
  GQLOrderStatusCode,
  GQLProductSaveInput,
  GQLQueryProductsArgs,
  GQLRole,
} from '~/types/gql'
import { ProductsResponse } from '~/repositories/product'
import { ProductFormType } from '~/features/product/types'
import { CategoryFormType } from '~/features/category/types'
import { CategoryListItem } from '~/pages/categories'
import { ProductPropertiesType } from '~/features/product-properties/types'
import { OrderFormType } from '~/features/order/factory/order-form-factory'
import { ValidationError } from '~/app/validation-error/interface'
import { Nullable } from '~/types'
import { CategoryProduct } from '~/features/category-product'
import { ManagerList } from '~/features/manager/manager-list'
import { ManagerFormType } from '~/features/manager/manager-form/manager-form.interface'
import { ReportManagerLogs } from '~/features/report/manager-logs/report-manager-logs.interface'
import {
  SearchQuery,
  SearchQueryTimeline,
} from '~/features/search/search-query/search-query.interface'
import { Banner } from '~/features/banner/banner'
import { Brand } from '~/features/brand'
import { BrandInputForm } from '~/features/brand/brand-input'

@injectable()
export abstract class Repository {
  public apollo: Apollo
  constructor(@inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo) {
    this.apollo = apollo
  }
}

// Ответ запроса с пагинацией
export interface PaginatedResponse<T> {
  totalItems: number
  totalPages: number
  items: T[]
}

export interface ProductRepositoryInterface {
  getProducts: (filters: GQLQueryProductsArgs) => Promise<ProductsResponse>
  getProductBySku: (sku: string) => Promise<ProductFormType | null>
  saveProduct: (
    input: GQLProductSaveInput
  ) => Promise<{
    product: Nullable<ProductFormType>
    errors: ValidationError[]
  }>
  deleteProduct: (productID: number) => Promise<boolean>
}

export interface CategoryRepositoryInterface {
  getCategories: (
    withAncestors: boolean,
    filter?: GQLCategoryFilter
  ) => Promise<CategoryListItem[]>
  getCategoriesTree: () => Promise<GQLCategoryTree[]>
  saveCategoriesTree: (input: GQLCategoryTreeSaveInput[]) => Promise<Boolean>
  getCategoryById: (id: string) => Promise<CategoryFormType>
  saveCategory: (
    input: GQLCategorySaveInput
  ) => Promise<{
    category: Nullable<CategoryFormType>
    errors: ValidationError[]
  }>
  deleteCategory: (categoryID: number) => Promise<boolean>
  getCategoryProducts: (categoryID: number) => Promise<CategoryProduct[]>
}

export interface OrderRepositoryInterface {
  getOrders: (page: number, limit: number) => Promise<GQLOrderPaginator>
  getOrderFormById: (id: string) => Promise<OrderFormType>
  getOrderShippings: () => Promise<GQLOrderShipping[]>
  getOrderPayments: () => Promise<GQLOrderPayment[]>
  getOrderStatuses: () => Promise<GQLOrderStatus[]>
  orderSave: (input: GQLOrderInput) => Promise<OrderFormType>
  orderDelete: (orderID: string) => Promise<boolean>
  orderStatusUpdate: (
    orderID: string,
    newStatusCode: GQLOrderStatusCode
  ) => Promise<OrderFormType>
}

export interface ProductPropertiesRepositoryInterface {
  getProperties: () => Promise<ProductPropertiesType>
}

export interface UserRepositoryInterface {
  login: (email: string, password: string) => Promise<boolean>
  getRoles: () => Promise<GQLRole[]>
  getManagers: () => Promise<ManagerList[]>
  getManager: (id: string) => Promise<ManagerFormType>
  createManager: (input: GQLManagerInput) => Promise<boolean>
  updateManager: (input: GQLManagerInput) => Promise<ManagerFormType>
  resetManagerPassword: (id: string) => Promise<boolean>
}

export interface ReportRepositoryInterface {
  getManagerLogs: (filter: GQLManagerLogsFilter) => Promise<ReportManagerLogs>
}

export interface ISearchRepository {
  getSearchHistory: (
    first: number,
    page: number
  ) => Promise<PaginatedResponse<SearchQuery>>
  getSearchHistoryTimeline: (
    first: number,
    page: number
  ) => Promise<PaginatedResponse<SearchQueryTimeline>>
}

export interface IBannerRepository {
  bannerSave: (
    input: GQLBannerInput[]
  ) => Promise<{
    success: boolean
    errors: ValidationError[]
  }>
  bannerRemove: (id: string) => Promise<boolean>
  getBanners: () => Promise<Banner[]>
}

export interface IBrandRepository {
  create: (
    input: GQLBrandInput
  ) => Promise<{ brand: Nullable<BrandInputForm>; errors: ValidationError[] }>

  getBrandsList: () => Promise<Brand[]>
  remove: (id: Brand['id']) => Promise<boolean>
  getBrandBySlug: (slug: string) => Promise<BrandInputForm>
}
