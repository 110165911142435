import { inject } from 'inversify'
import { IBannerRepository, Repository } from '~/abstracts/repository'
import { GQLBannerInput, GQLBanners, GQLMutation, GQLQuery } from '~/types/gql'
import DiTypes from '~/config/DiTypes'
import { Apollo } from '~/api/GraphqlClient'
import { IBannerFactory } from '~/abstracts/factory'
import { Banner } from '~/features/banner/banner'
import {
  ExpandedGraphQLError,
  ValidationError,
} from '~/app/validation-error/interface'
import { ValidationErrorFactory } from '~/app/validation-error'

export class BannerRepository extends Repository implements IBannerRepository {
  constructor(
    @inject(DiTypes.BANNER_FACTORY)
    private bannerFactory: IBannerFactory,
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo
  ) {
    super(apollo)
  }

  public async getBanners(): Promise<Banner[]> {
    const { data } = await this.apollo.client.query<{
      Banners: GQLQuery['Banners']
    }>({
      query: await import('~/graphql/banner/banners.graphql'),
    })

    return this.bannerFactory.fromArray(data.Banners)
  }

  public async bannerRemove(id: string): Promise<boolean> {
    const { data } = await this.apollo.client.mutate<
      { BannerRemove: GQLMutation['BannerRemove'] },
      { id: string }
    >({
      mutation: await import('~/graphql/banner/banner-remove.graphql'),
      variables: {
        id,
      },
    })

    return Boolean(data?.BannerRemove?.id)
  }

  public async bannerSave(
    banners: GQLBannerInput[]
  ): Promise<{
    success: boolean
    errors: ValidationError[]
  }> {
    const { data, errors } = await this.apollo.client.mutate<
      { BannerSave: GQLMutation['BannerSave'] },
      { input: GQLBanners }
    >({
      mutation: await import('~/graphql/banner/banner-save.graphql'),
      variables: {
        input: {
          banners,
        },
      },
    })

    return {
      success: Boolean(data?.BannerSave.length),
      errors: errors?.[0]
        ? ValidationErrorFactory.create(errors[0] as ExpandedGraphQLError)
        : [],
    }
  }
}
