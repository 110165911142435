import { injectable } from 'inversify'
import { Factory, IManagerFormFactory } from '~/abstracts/factory'
import { GQLUser } from '~/types/gql'
import { ManagerFormType } from '~/features/manager/manager-form/manager-form.interface'
import { inputToPhone } from '~/utils/format-phone'

@injectable()
export class ManagerFormFactory extends Factory<GQLUser, ManagerFormType>
  implements IManagerFormFactory {
  public create(input: GQLUser): ManagerFormType {
    return {
      id: input.id,
      name: input.name,
      phone: inputToPhone(input.phone),
      email: input.email,
      role: input.roles[0],
    }
  }
}
