import { Component, VNode, VueComponent } from '~/types/vue-ts-component'
import { AppNavbar } from '~/components/layout/navbar'

import '~/assets/styles/reset.css'
import '~/assets/styles/main.css'

@Component
export default class DefaultLayout extends VueComponent {
  render(): VNode {
    return (
      <div>
        <AppNavbar />
        <div class="container">
          <nuxt />
        </div>
      </div>
    )
  }
}
