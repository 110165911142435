import { setContext } from 'apollo-link-context'
import { getToken } from '~/utils/authorization'

export const getAuthLink = () => {
  return setContext((_, { headers }) => ({
    headers: {
      ...headers,
      Authorization: getToken(),
    },
  }))
}
