export interface ProductFilter {
  query: string
  priceRange: {
    from: string
    to: string
  }
  categoryID: number | null
  isActive: boolean | null
  isStockSync: boolean | null
}

const DEFAULT_FILTER: ProductFilter = {
  query: '',
  priceRange: {
    from: '',
    to: '',
  },
  categoryID: null,
  isActive: null,
  isStockSync: null,
}

export const getDefaultFilter = (): ProductFilter => {
  return Object.assign({}, DEFAULT_FILTER)
}
