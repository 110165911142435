import { injectable } from 'inversify'
import { Factory } from '~/abstracts/factory'
import { GQLProduct } from '~/types/gql'
import { centsToPrice } from '~/utils/format-price'
import { ProductListItem } from '~/pages/products'
import { formatIsStockUpdate } from '~/utils/format-date'
import { getMainImage } from '~/utils/product'

@injectable()
export class ProductListFactory extends Factory<GQLProduct, ProductListItem> {
  public create = (gqlProduct: GQLProduct): ProductListItem => {
    return {
      id: gqlProduct.id,
      name: gqlProduct.name,
      sku: gqlProduct.sku,
      slug: gqlProduct.slug,
      price: centsToPrice(gqlProduct.price),
      mainImage: getMainImage(gqlProduct.images),
      totalImages: gqlProduct.images?.length ?? 0,
      inStock: gqlProduct.inStock,
      inStockUpdate: gqlProduct.inStockUpdate
        ? formatIsStockUpdate(gqlProduct.inStockUpdate)
        : null,
    }
  }
}
