import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import { DateInput } from '~/types/timestamp'

// TODO: move to Timestamp module
dayjs.locale('ru')

export const formatDate = (
  dateInput: DateInput,
  template: string = 'DD.MM.YYYY'
): string => {
  return dayjs(dateInput).format(template)
}

export const formatTime = (dateInput: string): string => {
  return dayjs(dateInput).format('HH:mm')
}

export const formatIsStockUpdate = (dateInput: string | Date): string => {
  return dayjs(dateInput).format('DD.MM.YY в HH:MM')
}

export const getFirstDayOfCurrentMonth = (): DateInput => {
  return dayjs().startOf('month').add(180, 'minute').toISOString()
}

export const getYear = (date: DateInput): number => {
  return dayjs(date).year()
}

export const getMonth = (date: DateInput): number => {
  return dayjs(date).month()
}

export const getMonthName = (index: number): string => {
  return dayjs().startOf('year').month(index).format('MMMM')
}

export const setMonth = (date: DateInput, monthIndex: number): DateInput => {
  return dayjs(date).month(monthIndex).toISOString()
}

export const setYear = (date: DateInput, year: number): DateInput => {
  return dayjs(date).year(year).toISOString()
}

export const getEndOfMonth = (date: DateInput): DateInput => {
  return dayjs(date).endOf('month').toISOString()
}

export const getDateInput = (date: DateInput): string => {
  return dayjs(date).format('YYYY-MM-DD')
}
