import { injectable } from 'inversify'
import type { TreeNode } from 'liquor-tree'
import {
  GQLBanner,
  GQLBannerInput,
  GQLCategory,
  GQLCategorySaveInput,
  GQLCategoryTree,
  GQLCategoryTreeSaveInput,
  GQLManagerInput,
  GQLManagerLog,
  GQLManagerLogsFilter,
  GQLOrder,
  GQLOrderInput,
  GQLProduct,
  GQLProductFilter,
  GQLProductSaveInput,
  GQLSearchQuery,
  GQLUser,
} from '~/types/gql'
import { ProductFormType } from '~/features/product/types'
import { ProductListItem } from '~/pages/products'
import { CategoryFormType } from '~/features/category/types'
import { CategoryListItem } from '~/pages/categories'
import {
  CategoryTreeType,
  NodeData,
} from '~/components/pages/categories/categories-tree/types'
import { OrderListItem } from '~/pages/orders'
import { OrderFormType } from '~/features/order/factory/order-form-factory'
import { ProductFilter } from '~/features/product/product-filters'
import { CategoryProduct } from '~/features/category-product/category-product.interface'
import { ManagerList } from '~/features/manager/manager-list'
import { ManagerFormType } from '~/features/manager/manager-form/manager-form.interface'
import { ReportManagerLogs } from '~/features/report/manager-logs/report-manager-logs.interface'
import { ManagerLogsFilter } from '~/features/report/manager-logs/manager-logs-filter'
import { SearchQuery } from '~/features/search/search-query/search-query.interface'
import { BannerForm } from '~/features/banner/banner-form'
import { Banner } from '~/features/banner/banner'

/**
 * Фабрика используется для приведения данных
 * из api к локальному интерфейсу
 * из формы в api
 */
@injectable()
export abstract class Factory<T, B> {
  public abstract create(input: T): B

  public fromArray(input: T[]): B[] {
    return input.map((item) => this.create(item))
  }
}

export interface ProductListItemFactoryInterface {
  create: (gqlProduct: GQLProduct) => ProductListItem
  fromArray: (gqlProducts: GQLProduct[]) => ProductListItem[]
}

export interface ProductFormFactoryInterface {
  create: (input: GQLProduct) => ProductFormType
}

export interface ProductInputFactoryInterface {
  create: (input: ProductFormType) => Promise<GQLProductSaveInput>
}

export interface ProductFilterInputFactoryInterface {
  create: (input: ProductFilter) => GQLProductFilter
}

export interface CategoryFormFactoryInterface {
  create: (gqlCategory: GQLCategory) => CategoryFormType
}

export interface CategoryListItemFactoryInterface {
  create: (gqlCategory: GQLCategoryTree) => CategoryListItem
  fromArray: (gqlCategories: GQLCategoryTree[]) => CategoryListItem[]
  createWithAncestorsFromArray: (
    gqlCategories: GQLCategoryTree[]
  ) => CategoryListItem[]
}

export interface CategoryInputFactoryInterface {
  create: (input: CategoryFormType) => GQLCategorySaveInput
}

export interface CategoryTreeFactoryInterface {
  create: (input: GQLCategoryTree[]) => CategoryTreeType[]
}

export interface CategoryTreeInputFactoryInterface {
  create: (nodes: TreeNode<NodeData>[]) => GQLCategoryTreeSaveInput[]
}

export interface OrderListFactoryInterface {
  create: (input: GQLOrder) => OrderListItem
  fromArray: (input: GQLOrder[]) => OrderListItem[]
}

export interface OrderFormFactoryInterface {
  create: (input: GQLOrder) => OrderFormType
}

export interface OrderInputFactoryInterface {
  create: (input: OrderFormType) => GQLOrderInput
}

export interface CategoryProductFactoryInterface {
  create: (input: GQLProduct) => CategoryProduct
  fromArray: (input: GQLProduct[]) => CategoryProduct[]
}

export interface IManagerListFactory {
  create: (input: GQLUser) => ManagerList
  fromArray: (input: GQLUser[]) => ManagerList[]
}

export interface IManagerInputFactory {
  create: (input: ManagerFormType) => GQLManagerInput
}

export interface IManagerFormFactory {
  create: (input: GQLUser) => ManagerFormType
}

export interface IReportManagerLogsFactory {
  create: (input: GQLManagerLog[]) => ReportManagerLogs
}

export interface IReportManagerLogsInputFactory {
  create: (input: ManagerLogsFilter) => GQLManagerLogsFilter
}

export interface ISearchQueryFactory {
  create: (input: GQLSearchQuery) => SearchQuery
  fromArray: (input: GQLSearchQuery[]) => SearchQuery[]
}

export interface IProductFilterFactory {
  create: (input: string) => ProductFilter
}

export interface IBannerInputFactory {
  create: (input: BannerForm) => Promise<GQLBannerInput>
}

export interface IBannerFactory {
  create: (input: GQLBanner) => Banner
  fromArray: (input: GQLBanner[]) => Banner[]
}
