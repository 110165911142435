import { IBrandRepository, Repository } from '~/abstracts/repository'
import { GQLBrandInput, GQLMutation, GQLQuery } from '~/types/gql'
import { Nullable } from '~/types'
import {
  ExpandedGraphQLError,
  ValidationError,
} from '~/app/validation-error/interface'
import { ValidationErrorFactory } from '~/app/validation-error'
import { Brand } from '~/features/brand'
import { brandMapper } from '~/features/brand/brand.mapper'
import { BrandInputForm } from '~/features/brand/brand-input'
import { brandInputFormMapper } from '~/features/brand/brand-input/brand-input-form.mapper'

export class BrandRepository extends Repository implements IBrandRepository {
  public async create(
    input: GQLBrandInput
  ): Promise<{
    brand: Nullable<BrandInputForm>
    errors: ValidationError[]
  }> {
    const { data, errors } = await this.apollo.client.mutate<{
      BrandSave: GQLMutation['BrandSave']
    }>({
      mutation: await import('~/graphql/brand/brand-save.graphql'),
      variables: {
        input,
      },
    })

    return {
      brand: data?.BrandSave ? brandInputFormMapper(data.BrandSave) : null,
      errors: errors?.[0]
        ? ValidationErrorFactory.create(errors[0] as ExpandedGraphQLError)
        : [],
    }
  }

  public async getBrandsList(): Promise<Brand[]> {
    const { data } = await this.apollo.client.query<{
      Brands: GQLQuery['Brands']
    }>({
      query: await import('~/graphql/brand/brand-list.graphql'),
    })

    if (!data) return []

    return data.Brands.map(brandMapper)
  }

  public async remove(id: Brand['id']): Promise<boolean> {
    const { data } = await this.apollo.client.mutate<{
      BrandRemove: GQLMutation['BrandRemove']
    }>({
      mutation: await import('~/graphql/brand/brand-remove.graphql'),
      variables: {
        id,
      },
    })

    return Boolean(data?.BrandRemove?.id)
  }

  public async getBrandBySlug(slug: string): Promise<BrandInputForm> {
    const { data } = await this.apollo.client.query<{
      Brand: GQLQuery['Brand']
    }>({
      query: await import('~/graphql/brand/brand.graphql'),
      variables: {
        slug,
      },
    })

    if (!data.Brand?.id) throw new Error('not found')

    return brandInputFormMapper(data.Brand)
  }
}
