import { Repository } from '~/abstracts/repository'
import { GQLImageUploadInput, GQLMutation } from '~/types/gql'
import { cdnLink } from '~/utils/get-image-url'

export interface IImageRepository {
  uploadImage: (image: string) => Promise<string>
}

export class ImageRepository extends Repository implements IImageRepository {
  async uploadImage(image: string): Promise<string> {
    const { data } = await this.apollo.client.mutate<
      { ImageUpload: GQLMutation['ImageUpload'] },
      { input: GQLImageUploadInput }
    >({
      mutation: await import('~/graphql/image/image-upload.graphql'),
      variables: {
        input: {
          image,
        },
      },
    })

    if (!data?.ImageUpload) throw new Error('Image upload error')

    return cdnLink('/' + data.ImageUpload.url)
  }
}
