import Vue from 'vue'
import Vuex from 'vuex'
import { createVuexStore } from 'vuex-simple'

import { RootModule } from './root'

Vue.use(Vuex)

// create our module class instance
const instance = new RootModule()

// create and export our store
export default () =>
  createVuexStore(instance, {
    strict: true,
  })
