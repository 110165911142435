import { injectable } from 'inversify'
import { Factory, IManagerInputFactory } from '~/abstracts/factory'
import { ManagerFormType } from '~/features/manager/manager-form/manager-form.interface'
import { GQLManagerInput, GQLRole } from '~/types/gql'
import { phoneToInput } from '~/utils/format-phone'

@injectable()
export class ManagerInputFactory
  extends Factory<ManagerFormType, GQLManagerInput>
  implements IManagerInputFactory {
  public create(input: ManagerFormType): GQLManagerInput {
    /**
     * TODO: подумать как решить проблему с приведением типов
     * форма создания по дефолту пустая
     */
    return {
      id: input.id || undefined,
      name: input.name as string,
      email: input.email as string,
      phone: phoneToInput(input.phone as string),
      role: input.role as GQLRole,
    }
  }
}
