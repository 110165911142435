import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _135549be = () => interopDefault(import('../pages/brands/index.tsx' /* webpackChunkName: "pages/brands/index" */))
const _3754b0ea = () => interopDefault(import('../pages/categories.tsx' /* webpackChunkName: "pages/categories" */))
const _993c68da = () => interopDefault(import('../pages/login/index.tsx' /* webpackChunkName: "pages/login/index" */))
const _b1287e50 = () => interopDefault(import('../pages/managers/index.tsx' /* webpackChunkName: "pages/managers/index" */))
const _383f19d4 = () => interopDefault(import('../pages/orders.tsx' /* webpackChunkName: "pages/orders" */))
const _77fa9fd3 = () => interopDefault(import('../pages/products.tsx' /* webpackChunkName: "pages/products" */))
const _37329e35 = () => interopDefault(import('../pages/products/_page/index.tsx' /* webpackChunkName: "pages/products/[_]page/index" */))
const _80a22914 = () => interopDefault(import('../pages/brand/create/index.tsx' /* webpackChunkName: "pages/brand/create/index" */))
const _32481152 = () => interopDefault(import('../pages/category/create/index.tsx' /* webpackChunkName: "pages/category/create/index" */))
const _5f9bb1e0 = () => interopDefault(import('../pages/manager/create/index.tsx' /* webpackChunkName: "pages/manager/create/index" */))
const _bb8878fa = () => interopDefault(import('../pages/page-manager/main/index.tsx' /* webpackChunkName: "pages/page-manager/main/index" */))
const _130953e4 = () => interopDefault(import('../pages/product/create/index.tsx' /* webpackChunkName: "pages/product/create/index" */))
const _45153ab4 = () => interopDefault(import('../pages/report/manager-logs/index.tsx' /* webpackChunkName: "pages/report/manager-logs/index" */))
const _a5338c42 = () => interopDefault(import('../pages/search/history/index.tsx' /* webpackChunkName: "pages/search/history/index" */))
const _00bcb6ec = () => interopDefault(import('../pages/brand/_slug/index.tsx' /* webpackChunkName: "pages/brand/[_]slug/index" */))
const _1f757ddb = () => interopDefault(import('../pages/category/_id/index.tsx' /* webpackChunkName: "pages/category/[_]id/index" */))
const _c971de7c = () => interopDefault(import('../pages/manager/_id/index.tsx' /* webpackChunkName: "pages/manager/[_]id/index" */))
const _4f06acba = () => interopDefault(import('../pages/order/_id/index.tsx' /* webpackChunkName: "pages/order/[_]id/index" */))
const _26b527f0 = () => interopDefault(import('../pages/product/_sku/index.tsx' /* webpackChunkName: "pages/product/[_]sku/index" */))
const _5fbd988e = () => interopDefault(import('../pages/index.tsx' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/brands",
    component: _135549be,
    name: "brands"
  }, {
    path: "/categories",
    component: _3754b0ea,
    name: "categories"
  }, {
    path: "/login",
    component: _993c68da,
    name: "login"
  }, {
    path: "/managers",
    component: _b1287e50,
    name: "managers"
  }, {
    path: "/orders",
    component: _383f19d4,
    name: "orders"
  }, {
    path: "/products",
    component: _77fa9fd3,
    name: "products",
    children: [{
      path: ":page",
      component: _37329e35,
      name: "products-page"
    }]
  }, {
    path: "/brand/create",
    component: _80a22914,
    name: "brand-create"
  }, {
    path: "/category/create",
    component: _32481152,
    name: "category-create"
  }, {
    path: "/manager/create",
    component: _5f9bb1e0,
    name: "manager-create"
  }, {
    path: "/page-manager/main",
    component: _bb8878fa,
    name: "page-manager-main"
  }, {
    path: "/product/create",
    component: _130953e4,
    name: "product-create"
  }, {
    path: "/report/manager-logs",
    component: _45153ab4,
    name: "report-manager-logs"
  }, {
    path: "/search/history",
    component: _a5338c42,
    name: "search-history"
  }, {
    path: "/brand/:slug?",
    component: _00bcb6ec,
    name: "brand-slug"
  }, {
    path: "/category/:id?",
    component: _1f757ddb,
    name: "category-id"
  }, {
    path: "/manager/:id?",
    component: _c971de7c,
    name: "manager-id"
  }, {
    path: "/order/:id?",
    component: _4f06acba,
    name: "order-id"
  }, {
    path: "/product/:sku?",
    component: _26b527f0,
    name: "product-sku"
  }, {
    path: "/",
    component: _5fbd988e,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
