import { injectable } from 'inversify'
import { Factory, IManagerListFactory } from '~/abstracts/factory'
import { GQLUser } from '~/types/gql'
import { ManagerList } from '~/features/manager/manager-list'

@injectable()
export class ManagerListFactory extends Factory<GQLUser, ManagerList>
  implements IManagerListFactory {
  public create(input: GQLUser): ManagerList {
    return {
      id: input.id,
      email: input.email,
      name: input.name,
      phone: input.phone,
      roles: input.roles,
    }
  }
}
