import { injectable } from 'inversify'
import { GQLCategoryTree } from '~/types/gql'
import { CategoryTreeType } from '~/components/pages/categories/categories-tree/types'
import { CategoryTreeFactoryInterface } from '~/abstracts/factory'

@injectable()
export class CategoryTreeFactory implements CategoryTreeFactoryInterface {
  create(input: GQLCategoryTree[]): CategoryTreeType[] {
    const flatTree = input.map(this.createNode)

    return this.flatToTree(flatTree)
  }

  private createNode(category: GQLCategoryTree): CategoryTreeType {
    return {
      text: category.name,
      children: [],
      data: {
        id: Number(category.id),
        parentId: category.parent ? Number(category.parent.id) : undefined,
        slug: category.slug,
      },
      state: {
        draggable: true,
        disabled: false,
        expanded: false,
        selectable: false,
      },
    }
  }

  private flatToTree(categories: CategoryTreeType[]): CategoryTreeType[] {
    const roots: CategoryTreeType[] = []
    const map: { [key: string]: number } = {}

    for (let i = 0; i < categories.length; i += 1) {
      map[categories[i].data.id] = i
    }

    for (let i = 0; i < categories.length; i += 1) {
      const node: CategoryTreeType = categories[i]
      if (node.data.parentId) {
        categories[map[node.data.parentId]].children.push(node)
      } else {
        roots.push(node)
      }
    }
    return roots
  }
}
