import { NotificationProgrammatic, DialogProgrammatic } from 'buefy'
import { BDialogConfig, BNotificationConfig } from 'buefy/types/components'
import { RootModule } from '~/store/root'

export class ApplicationModule {
  private root: RootModule

  constructor(root: RootModule) {
    this.root = root
  }

  public showNotification(config: BNotificationConfig): void {
    NotificationProgrammatic.open({
      // @ts-ignore
      closable: false,
      duration: 3000,
      ...config,
    })
  }

  public showConfirmationDialog(config: BDialogConfig) {
    DialogProgrammatic.confirm({
      cancelText: 'Отмена',
      confirmText: 'Подтвердить',
      ...config,
    })
  }

  public showAlertDialog(config: BDialogConfig): void {
    DialogProgrammatic.alert({
      ...config,
    })
  }
}
