export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

export const formatEncodedString = (base64: string): string =>
  base64.replace(/^data(.*);base64,?/, '')

export const fileToInput = async (file: File): Promise<string> => {
  return formatEncodedString(await toBase64(file))
}

type FileStringInput = {
  dataUrl: string
  name?: string
  type?: string
}

export const base64ToFile = async ({
  dataUrl,
  name = '',
  type = 'image/jpeg',
}: FileStringInput): Promise<File | null> => {
  try {
    const response: Response = await fetch(dataUrl)
    const blob = await response.blob()
    return new File([blob], name, { type })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return null
  }
}
