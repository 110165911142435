import { Factory, OrderFormFactoryInterface } from '~/abstracts/factory'
import { GQLOrder, GQLOrderItem, GQLGalleryImage } from '~/types/gql'
import {
  OrderFormDeliveryData,
  OrderFormProductItem,
  OrderFormType,
} from '~/features/order/factory/order-form-factory/interface'
import { centsToPrice } from '~/utils/format-price'
import { cdnLink, getMediumImage } from '~/utils/get-image-url'
import { DEFAULT_ORDER_DELIVERY_DATA } from '~/features/order/factory/order-form-factory/consts'

export class OrderFormFactory extends Factory<GQLOrder, OrderFormType>
  implements OrderFormFactoryInterface {
  public create(input: GQLOrder): OrderFormType {
    return {
      id: input.id,
      orderNumber: input.orderNumber,
      amount: centsToPrice(input.amount),
      date: input.createdAt,
      status: input.status.code,
      customer: input.customer.name,
      customerPhone: input.customer.phone ?? '',
      customerEmail: input.customer.email ?? '',
      userComment: input.comment ?? '',
      deliveryData: this.getDeliveryData(input.address),
      products: input.items.map(this.getOrderFormProductItem),
      shipping: input.shipping.code,
      shippingCost: input.shipping.cost,
    }
  }

  private getDeliveryData(input: GQLOrder['address']): OrderFormDeliveryData {
    if (!input) {
      return DEFAULT_ORDER_DELIVERY_DATA
    }

    return {
      address: input.address,
      floor: input.floor ?? '',
      apartment: input.apartment ?? '',
      entrance: input.entrance ?? '',
      intercom: input.intercom ?? '',
    }
  }

  private getOrderFormProductItem(
    orderItem: GQLOrderItem
  ): OrderFormProductItem {
    return {
      name: orderItem.name,
      sku: orderItem.sku,
      quantity: orderItem.quantity,
      price: centsToPrice(orderItem.price),
      oldPrice: centsToPrice(orderItem.oldPrice),
      productImage: OrderFormFactory.getOrderFormProductItemImage(
        orderItem.product?.images
      ),
    }
  }

  private static getOrderFormProductItemImage(
    images: GQLGalleryImage[] = []
  ): string {
    const [mainImage] = images

    return mainImage
      ? cdnLink(getMediumImage(mainImage.image))
      : require('~/assets/images/no-img-good.png')
  }
}
