import { CategoryFormFactoryInterface, Factory } from '~/abstracts/factory'
import { GQLCategory } from '~/types/gql'
import { CategoryFormType } from '~/features/category/types'
import { SeoFormType } from '~/features/seo/types'

export class CategoryFormFactory extends Factory<GQLCategory, CategoryFormType>
  implements CategoryFormFactoryInterface {
  public create(gqlCategory: GQLCategory): CategoryFormType {
    const category: CategoryFormType = {
      id: Number(gqlCategory.id),
      name: gqlCategory.name,
      slug: gqlCategory.slug,
      onlinePayment: gqlCategory.onlinePayment,
      isActive: gqlCategory.isActive,
      description: gqlCategory.description,
      seo: this.getCategorySeo(gqlCategory.seo),
      productFilters: gqlCategory.filters ?? undefined,
      flags: gqlCategory.flags || [],
    }
    if (gqlCategory.parent) {
      category.parentId = Number(gqlCategory.parent.id)
      category.parent = {
        id: Number(gqlCategory.parent.id),
        name: gqlCategory.parent.name,
      }
    }

    return category
  }

  private getCategorySeo(seo: GQLCategory['seo']): SeoFormType {
    return {
      title: seo.title,
      keywords: seo.keywords,
      description: seo.description,
    }
  }
}
