import { injectable } from 'inversify'
import { CategoryProductFactoryInterface, Factory } from '~/abstracts/factory'
import { GQLProduct } from '~/types/gql'
import { CategoryProduct } from '~/features/category-product/category-product.interface'
import { centsToPrice } from '~/utils/format-price'
import { getMainImage } from '~/utils/product'

@injectable()
export class CategoryProductFactory extends Factory<GQLProduct, CategoryProduct>
  implements CategoryProductFactoryInterface {
  public create(input: GQLProduct): CategoryProduct {
    return {
      id: input.id,
      name: input.name,
      sku: input.sku,
      price: centsToPrice(input.price),
      image: getMainImage(input.images),
      inStock: input.inStock,
      oldPrice: input.oldPrice ? centsToPrice(input.oldPrice) : undefined,
    }
  }
}
