import { GQLRole } from '~/types/gql'

/**
 * Перечень активных ролей в системе. Пользователи с ролью, не включенной в список,
 * будут перенаправлены на страницу логина
 */
export const ALLOWED_ROLES = [
  GQLRole.Admin,
  GQLRole.OrderManager,
  GQLRole.ContentManager,
] as const
