import { GQLBrand } from '~/types/gql'
import { BrandInputForm } from '~/features/brand/brand-input/brand-input.form'
import { cdnLink } from '~/utils/get-image-url'

export const brandInputFormMapper = (input: GQLBrand): BrandInputForm => ({
  id: input.id,
  name: input.name,
  slug: input.slug,
  isActive: input.isPageActive,
  title: input.title,
  description: input.description,
  seo: {
    title: input.seo.title,
    description: input.seo.description,
    keywords: input.seo.keywords,
  },
  logo: {
    title: '',
    src: input.logo ? cdnLink('/' + input.logo) : undefined,
  },
  banner: {
    title: '',
    src: input.banner ? cdnLink(`/${input.banner}`) : undefined,
  },
})
