import {
  Factory,
  ProductFilterInputFactoryInterface,
} from '~/abstracts/factory'
import { ProductFilter } from '~/features/product/product-filters'
import { GQLProductFilter } from '~/types/gql'
import { validatePrice } from '~/utils/validate-price'
import { priceToCents } from '~/utils/format-price'

export class ProductFilterInputFactory
  extends Factory<ProductFilter, GQLProductFilter>
  implements ProductFilterInputFactoryInterface {
  public create(input: ProductFilter): GQLProductFilter {
    return {
      query: this.getTextOrUndefined(input.query),
      priceRange: this.getPriceRange(input.priceRange),
      categories: this.getCategories(input.categoryID),
      isActive: input.isActive ?? undefined,
      isStockSync: input.isStockSync ?? undefined,
    }
  }

  private getTextOrUndefined(name: string): string | undefined {
    return name.length ? name : undefined
  }

  private getPriceRange(
    priceRange: ProductFilter['priceRange']
  ): GQLProductFilter['priceRange'] {
    if (!priceRange.from.length && !priceRange.to.length) return undefined

    return {
      from: this.formatFilterPrice(priceRange.from),
      to: this.formatFilterPrice(priceRange.to),
    }
  }

  private formatFilterPrice(price: string): number | undefined {
    return validatePrice(price) ? priceToCents(parseFloat(price)) : undefined
  }

  private getCategories(
    input: ProductFilter['categoryID']
  ): GQLProductFilter['categories'] {
    if (!input) return
    // TODO: update schema
    return [String(input)]
  }
}
