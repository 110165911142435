import { injectable } from 'inversify'
import type { TreeNode } from 'liquor-tree'
import { GQLCategoryTreeSaveInput } from '~/types/gql'
import { NodeData } from '~/components/pages/categories/categories-tree/types'
import { CategoryTreeInputFactoryInterface } from '~/abstracts/factory'

@injectable()
export class CategoryTreeInputFactory
  implements CategoryTreeInputFactoryInterface {
  create(nodes: TreeNode<NodeData>[]): GQLCategoryTreeSaveInput[] {
    return this.toArray(nodes, [], null)
  }

  private toArray(
    nodes: any[] = [],
    arr: GQLCategoryTreeSaveInput[] = [],
    parent: number | null
  ): GQLCategoryTreeSaveInput[] {
    for (let i = 0; i < nodes.length; i++) {
      arr.push({
        id: String(nodes[i].data.id),
        parentId: parent ? String(parent) : null,
      })
      this.toArray(nodes[i].children, arr, nodes[i].data.id)
    }
    return arr
  }
}
