const TOKEN_PREFIX = 'Bearer '
const STORAGE_KEY = 'token'

export const setToken = (token: string): void => {
  window.localStorage.setItem(STORAGE_KEY, TOKEN_PREFIX + token)
}

export const getToken = (): string | null => {
  return window.localStorage.getItem(STORAGE_KEY)
}

export const removeToken = (): void => {
  window.localStorage.removeItem(STORAGE_KEY)
}
