import { GQLProduct } from '~/types/gql'
import { cdnLink, getTinyImage } from '~/utils/get-image-url'

export const getMainImage = (
  images: GQLProduct['images']
): string | undefined => {
  if (!images?.length) return

  const mainImage = images.find((image) => image.sortOrder === 0)

  if (!mainImage) return

  return cdnLink(getTinyImage(mainImage.image))
}
