import { Component, VNode, VueComponent } from '~/types/vue-ts-component'

@Component
export default class EmptyLayout extends VueComponent {
  render(): VNode {
    return (
      <div>
        <nuxt />
      </div>
    )
  }
}
