import { inject } from 'inversify'
import { OrderRepositoryInterface, Repository } from '~/abstracts/repository'
import { OrderFormFactoryInterface } from '~/abstracts/factory'
import DiTypes from '~/config/DiTypes'
import { Apollo } from '~/api/GraphqlClient'
import {
  GQLMutation,
  GQLOrderInput,
  GQLOrderPaginator,
  GQLOrderPayment,
  GQLOrderShipping,
  GQLOrderStatus,
  GQLOrderStatusCode,
  GQLQuery,
} from '~/types/gql'
import { OrderFormType } from '~/features/order/factory/order-form-factory'

export class OrderRepository extends Repository
  implements OrderRepositoryInterface {
  private orderFormFactory: OrderFormFactoryInterface

  constructor(
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo,
    @inject(DiTypes.ORDER_FORM_FACTORY)
    orderFormFactory: OrderFormFactoryInterface
  ) {
    super(apollo)
    this.orderFormFactory = orderFormFactory
  }

  public async getOrders(
    page: number,
    limit: number
  ): Promise<GQLOrderPaginator> {
    const { data } = await this.apollo.client.query<{
      Orders: GQLQuery['Orders']
    }>({
      query: await import('~/graphql/orders.graphql'),
      variables: { page, limit },
    })

    if (!data.Orders) {
      throw new Error('Orders are undefined')
    }

    return data.Orders
  }

  public async getOrderFormById(id: string): Promise<OrderFormType> {
    const { data } = await this.apollo.client.query<{
      Order: GQLQuery['Order']
    }>({
      query: await import('~/graphql/order.graphql'),
      fetchPolicy: 'no-cache',
      variables: { id },
    })

    if (!data.Order?.id) {
      throw new Error(`Order with ID ${id} not found`)
    }

    return this.orderFormFactory.create(data.Order)
  }

  public async getOrderPayments(): Promise<GQLOrderPayment[]> {
    const { data } = await this.apollo.client.query<{
      OrderPayments: GQLQuery['OrderPayments']
    }>({
      query: await import('~/graphql/order_payments.graphql'),
    })

    return data.OrderPayments
  }

  public async getOrderShippings(): Promise<GQLOrderShipping[]> {
    const { data } = await this.apollo.client.query<{
      OrderShippings: GQLQuery['OrderShippings']
    }>({
      query: await import('~/graphql/order_shippings.graphql'),
    })

    return data.OrderShippings
  }

  public async getOrderStatuses(): Promise<GQLOrderStatus[]> {
    const { data } = await this.apollo.client.query<{
      OrderStatuses: GQLQuery['OrderStatuses']
    }>({
      query: await import('~/graphql/order_statuses.graphql'),
    })

    return data.OrderStatuses
  }

  public async orderSave(input: GQLOrderInput): Promise<OrderFormType> {
    const { data } = await this.apollo.client.mutate<{
      OrderSave: GQLMutation['OrderSave']
    }>({
      mutation: await import('~/graphql/order_save.graphql'),
      variables: { input },
    })
    if (!data?.OrderSave) throw new Error('Ошибка сохранения')

    return this.orderFormFactory.create(data.OrderSave)
  }

  public async orderDelete(orderID: string): Promise<boolean> {
    const { data } = await this.apollo.client.mutate<{
      OrderRemove: GQLMutation['OrderRemove']
    }>({
      mutation: await import('~/graphql/order_remove.graphql'),
      variables: { orderID },
    })

    return Boolean(data?.OrderRemove?.id)
  }

  public async orderStatusUpdate(
    orderID: string,
    newStatusCode: GQLOrderStatusCode
  ): Promise<OrderFormType> {
    const { data } = await this.apollo.client.mutate<{
      OrderStatusUpdate: GQLMutation['OrderStatusUpdate']
    }>({
      mutation: await import('~/graphql/order_status_update.graphql'),
      variables: { orderID, newStatusCode },
    })

    if (!data?.OrderStatusUpdate) throw new Error('Ошибка сохранения')

    return this.orderFormFactory.create(data.OrderStatusUpdate)
  }
}
