const formatter = new Intl.NumberFormat('ru', {
  style: 'currency',
  currency: 'Rub',
  minimumFractionDigits: 2,
})

export const formatPrice = (price: number): string => formatter.format(price)

export const priceToCents = (price: number): number => Math.ceil(price * 100)

export const centsToPrice = (cents: number): number => cents / 100

export const priceStringToNum = (price: string): number => parseFloat(price)
