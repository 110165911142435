import { GQLBrand } from '~/types/gql'
import { Brand } from '~/features/brand/brand'
import { cdnLink } from '~/utils/get-image-url'

export const brandMapper = (input: GQLBrand): Brand => ({
  id: input.id,
  name: input.name,
  slug: input.slug,
  isPageActive: input.isPageActive,
  logo: input.logo ? cdnLink('/' + input.logo) : undefined,
  title: input.title,
  description: input.description,
})
