import { injectable } from 'inversify'
import { ProductInputFactoryInterface } from '~/abstracts/factory'
import {
  ProductCategoriesFormType,
  ProductFormType,
  ProductImageFormType,
  ProductInstructionFormType,
} from '~/features/product/types'
import {
  GQLProductCategoryInput,
  GQLProductImageSaveInput,
  GQLProductSaveInput,
} from '~/types/gql'
import { priceToCents, priceStringToNum } from '~/utils/format-price'
import { toBase64 } from '~/utils/convert-file'
import { isString, stringIsNotEmpty } from '~/utils/string'
import { Nullable } from '~/types'

@injectable()
export class ProductInputFactory implements ProductInputFactoryInterface {
  async create(input: ProductFormType): Promise<GQLProductSaveInput> {
    const product: GQLProductSaveInput = {
      name: input.name,
      slug: input.slug,
      sku: input.sku,
      isActive: input.isActive,
      description: input.description,
      instruction: this.createInstruction(input.instruction),
      price: priceToCents(priceStringToNum(input.price)),
      oldPrice: priceToCents(priceStringToNum(input.oldPrice)) ?? 0,
      images: await this.createImages(input.images),
      categories: this.createCategories(input.categories),
      categoryId: this.findMainCategory(input.categories),
      seo: {
        title: input.seo.title,
        keywords: input.seo.keywords,
        description: input.seo.description,
      },
      country: this.createNullableString(input.country),
      manufacturer: this.createNullableString(input.manufacturer),
      ingredient: this.createNullableString(input.ingredient),
      brand: this.createNullableString(input.brand),
      isStockSync: input.isStockSync,
      searchWords: input.searchWords?.length ? input.searchWords : undefined,
    }
    if (input.id) {
      product.id = String(input.id)
    }

    return product
  }

  async createImages(
    images: ProductImageFormType[]
  ): Promise<GQLProductImageSaveInput[]> {
    return await Promise.all(
      images.map(async (image) => await this.createImage(image))
    )
  }

  private async createImage(
    input: ProductImageFormType
  ): Promise<GQLProductImageSaveInput> {
    if (input.file === undefined && input.id === undefined) {
      throw new Error('Input ID was undefined and file was not set')
    }

    const image: GQLProductImageSaveInput = {
      title: input.title,
      sortOrder: input.sortOrder,
    }

    if (input.file !== undefined) {
      const encodedString = await toBase64(input.file)
      image.image = encodedString.replace(/^data(.*);base64,?/, '')
      return image
    }

    image.id = String(input.id)
    return image
  }

  private createCategories(
    categories: ProductCategoriesFormType[]
  ): GQLProductCategoryInput[] {
    return categories.map(this.createCategory)
  }

  private createCategory(
    category: ProductCategoriesFormType
  ): GQLProductCategoryInput {
    return {
      id: String(category.id),
      sortOrder: 0,
    }
  }

  private findMainCategory(
    categories: ProductCategoriesFormType[]
  ): string | null {
    const mainCategory = categories.find((category) => {
      return category.main
    })
    if (mainCategory === undefined) {
      return null
    }

    return String(mainCategory.id)
  }

  private createInstruction(
    instruction: ProductInstructionFormType[] | null | undefined
  ) {
    if (instruction === null || instruction === undefined) {
      return null
    }

    if (instruction.length === 0) {
      return null
    }

    return instruction
  }

  private createNullableString(input: Nullable<string>): string | null {
    if (!isString(input)) return null
    return stringIsNotEmpty(input) ? input : null
  }
}
