import { GraphQLError } from 'graphql'
import { NotificationProgrammatic } from 'buefy'
import type { Breadcrumb } from '@sentry/types'
import { Severity } from '@sentry/types'
import type { Operation } from 'apollo-link'
import { GQLValidationCode } from '~/types/gql'
import { container } from '~/config/inversify'
import DiTypes from '~/config/DiTypes'
import { ILogger } from '~/app/logger'
import {
  geBreadcrumb,
  getTagsFromExtensions,
} from '~/api/GraphqlClient/links/error/api/GraphqlClient/links/error-link/error.utils'

const SKIPPED_CATEGORIES = [GQLValidationCode.Validation]

export const graphqlErrorHandler = (
  operation: Operation,
  errors: ReadonlyArray<GraphQLError>
) => {
  const logger = container.get<ILogger>(DiTypes.LOGGER)

  errors.forEach((error) => {
    const breadcrumb: Breadcrumb = geBreadcrumb(operation, error)

    logger.withScope(
      (scope) => {
        const tags = getTagsFromExtensions(error.extensions)
        tags &&
          Object.entries(tags).forEach(([key, value]) => {
            scope.setTag(key, value)
          })

        logger.log(error.message, Severity.Error)
      },
      [breadcrumb]
    )

    if (SKIPPED_CATEGORIES.includes(error.extensions?.category)) return

    NotificationProgrammatic.open({
      // @ts-ignore
      closable: true,
      indefinite: true,
      type: 'is-danger',
      message: error.message,
    })
  })
}
