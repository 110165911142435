import { Mutation, State, Getter, Action } from 'vuex-simple'
import { roleIsAllowed } from './utils'
import { container } from '~/config/inversify'
import { UserRepositoryInterface } from '~/abstracts/repository'
import DiTypes from '~/config/DiTypes'
import { GQLRole } from '~/types/gql'
import { removeToken } from '~/utils/authorization'

export class AuthenticationModule {
  @State()
  userRoles: GQLRole[] = []

  @Getter()
  get hasRole(): boolean {
    return Boolean(this.userRoles.length)
  }

  @Getter()
  get roleIsAllowed(): boolean {
    return this.userRoles.some(roleIsAllowed)
  }

  @Getter()
  get isAdmin(): boolean {
    return this.userRoles.includes(GQLRole.Admin)
  }

  @Getter()
  get isOrderManager(): boolean {
    return this.userRoles.includes(GQLRole.OrderManager)
  }

  @Getter()
  get isContentManager(): boolean {
    return this.userRoles.includes(GQLRole.ContentManager)
  }

  @Mutation()
  updateUserRole(userRoles: GQLRole[]): void {
    this.userRoles = userRoles
  }

  @Action()
  public logout(): void {
    this.userRoles = []
    removeToken()
  }

  public async getUserRoles(): Promise<void> {
    try {
      this.updateUserRole(
        await container
          .get<UserRepositoryInterface>(DiTypes.USER_REPOSITORY)
          .getRoles()
      )
    } catch (error) {
      // TODO: втащить сюда логгер
      // возможно стоит покащать уведомление, что токен протух
      removeToken()
    }
  }
}
