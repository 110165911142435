import { OrderFormType } from '~/features/order/factory/order-form-factory/interface'
import { GQLOrderShippingCode, GQLOrderStatusCode } from '~/types/gql'

export const DEFAULT_ORDER_DELIVERY_DATA = {
  address: '',
  apartment: '',
  entrance: '',
  floor: '',
  intercom: '',
}

export const DEFAULT_ORDER_FORM: OrderFormType = {
  orderNumber: '',
  amount: 0,
  date: '',
  status: GQLOrderStatusCode.New,
  customer: '',
  customerPhone: '',
  customerEmail: '',
  deliveryData: DEFAULT_ORDER_DELIVERY_DATA,
  products: [],
  shipping: GQLOrderShippingCode.Pickup,
  shippingCost: 0,
  userComment: '',
}
