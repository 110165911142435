import { equals, clone } from 'ramda'

export const stringifyObject = (target: object): string =>
  JSON.stringify(target, null, 2)

export const isEmpty = (target?: object): boolean =>
  target == null || !(Object.keys(target) || target).length

export const trimObject = (target: any): object => {
  if (isEmpty(target)) {
    return {}
  }

  return Object.keys(target)
    .filter((key: string) => target?.[key])
    .reduce(
      (a: object, key: string): object => ({
        ...a,
        [key]: target[key],
      }),
      {}
    )
}

// https://ramdajs.com/docs/#equals
export const areEqual = <T extends Object>(obj1: T, obj2: T): boolean => {
  return equals(obj1, obj2)
}

// https://ramdajs.com/docs/#clone
export function deepCopy<T>(obj: T): T {
  return clone<T>(obj)
}
