import { Factory } from '~/abstracts/factory'
import { BannerForm, BannerFormFile } from '~/features/banner/banner-form'
import { GQLBannerInput } from '~/types/gql'
import { fileToInput } from '~/utils/convert-file'
import { isFile, isString } from '~/utils/type-guards'

export class BannerInputFactory extends Factory<
  BannerForm,
  Promise<GQLBannerInput>
> {
  async create(input: BannerForm): Promise<GQLBannerInput> {
    return {
      desktopImage: await this.createFileInput(input.desktopImage),
      mobileImage: await this.createFileInput(input.mobileImage),
      url: input.url || undefined,
      isActive: false,
      sortOrder: 0,
    }
  }

  private async createFileInput(
    file: BannerFormFile
  ): Promise<string | undefined> {
    if (isString(file)) return file
    if (isFile(file)) return await fileToInput(file)
  }
}
