import { Context } from '@nuxt/types'
/**
 * Управляем редиректом странице истории поисковых запросов
 */
// TODO: подумать, где хранить
export enum HistoryTab {
  Grouped = 'grouped',
  Timeline = 'timeline',
}
export const DEFAULT_TAB = HistoryTab.Grouped

export default function ({ route, redirect }: Context) {
  if (
    !route.query.tab ||
    !Object.values(HistoryTab).includes(route.query.tab as HistoryTab)
  ) {
    redirect({
      path: route.path,
      query: {
        tab: DEFAULT_TAB,
      },
    })
  }
}
