import { AppRoute } from '~/config/routers'
import { ALLOWED_ROLES } from '~/config/authentication'
import { GQLRole } from '~/types/gql'

export type NavLink = {
  href: string
  title: string
}

type AppRouteNavigation =
  | AppRoute.Products
  | AppRoute.Categories
  | AppRoute.Orders
  | AppRoute.Managers
  | AppRoute.ReportManagerLogs
  | AppRoute.SearchHistory
  | AppRoute.MainPageManager
  | AppRoute.Brands

const NAV_LINKS: Record<AppRouteNavigation, NavLink> = {
  [AppRoute.Products]: {
    href: '/products/1',
    title: 'Товары',
  },
  [AppRoute.Categories]: {
    href: '/categories',
    title: 'Категории',
  },
  [AppRoute.Orders]: {
    href: '/orders',
    title: 'Заказы',
  },
  [AppRoute.Managers]: {
    href: '/managers',
    title: 'Менеджеры',
  },
  [AppRoute.ReportManagerLogs]: {
    href: '/report/manager-logs',
    title: 'Отчет',
  },
  [AppRoute.SearchHistory]: {
    href: '/search/history',
    title: 'Поисковые фразы',
  },
  [AppRoute.MainPageManager]: {
    href: '/page-manager/main',
    title: 'Управление баннерами',
  },
  [AppRoute.Brands]: {
    href: '/brands',
    title: 'Бренды',
  },
}

const ADMIN_NAVBAR_LINKS: NavLink[] = [
  NAV_LINKS[AppRoute.Products],
  NAV_LINKS[AppRoute.Categories],
  NAV_LINKS[AppRoute.Brands],
  NAV_LINKS[AppRoute.Orders],
  NAV_LINKS[AppRoute.Managers],
  NAV_LINKS[AppRoute.ReportManagerLogs],
  NAV_LINKS[AppRoute.SearchHistory],
  NAV_LINKS[AppRoute.MainPageManager],
]

const ORDER_MANAGER_LINKS: NavLink[] = [
  NAV_LINKS[AppRoute.Products],
  NAV_LINKS[AppRoute.Categories],
  NAV_LINKS[AppRoute.Orders],
]

const CONTENT_MANAGER_LINKS: NavLink[] = [
  NAV_LINKS[AppRoute.Products],
  NAV_LINKS[AppRoute.Categories],
  NAV_LINKS[AppRoute.Brands],
  NAV_LINKS[AppRoute.ReportManagerLogs],
  NAV_LINKS[AppRoute.SearchHistory],
  NAV_LINKS[AppRoute.MainPageManager],
]

const NAV_LINKS_BY_ROLE: Record<typeof ALLOWED_ROLES[number], NavLink[]> = {
  [GQLRole.Admin]: ADMIN_NAVBAR_LINKS,
  [GQLRole.ContentManager]: CONTENT_MANAGER_LINKS,
  [GQLRole.OrderManager]: ORDER_MANAGER_LINKS,
}

export const getNavLinks = (roles: GQLRole[]): NavLink[] => {
  if (roles.includes(GQLRole.Admin)) return NAV_LINKS_BY_ROLE[GQLRole.Admin]
  if (roles.includes(GQLRole.OrderManager))
    return NAV_LINKS_BY_ROLE[GQLRole.OrderManager]
  if (roles.includes(GQLRole.ContentManager))
    return NAV_LINKS_BY_ROLE[GQLRole.ContentManager]

  return []
}
