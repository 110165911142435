import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { injectable } from 'inversify'
import { Severity } from '@sentry/types'
import type { Breadcrumb } from '@sentry/types'
import type { Scope } from '@sentry/hub'
import { ILogger } from '~/app/logger/logger.interface'

@injectable()
export class AppLogger implements ILogger {
  constructor() {
    if (process.env.sentryEndpoint === undefined) {
      // eslint-disable-next-line no-console
      console.error('Sentry endpoint is undefined')
      return
    }
    AppLogger.initiate()
  }

  public error(exception: any): void {
    Sentry.captureException(exception, {
      level: Severity.Error,
    })
  }

  public log(message: string, severity?: Severity): void {
    Sentry.captureMessage(message, {
      level: severity || Severity.Log,
    })
  }

  public withScope(
    callback: (scope: Scope) => void,
    breadcrumbs?: Breadcrumb[]
  ): void {
    Sentry.withScope((scope) => {
      breadcrumbs?.length &&
        breadcrumbs.forEach((breadcrumb) => {
          Sentry.addBreadcrumb(breadcrumb)
        })
      callback(scope)
    })
  }

  private static initiate(): void {
    Sentry.init({
      Vue,
      dsn: process.env.sentryEndpoint,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: process.env.environment,
    })
  }
}
