import { getDefaultFilter, ProductFilter } from '../product-filters'
import { Factory, IProductFilterFactory } from '~/abstracts/factory'

export class ProductFilterFactory extends Factory<string, ProductFilter>
  implements IProductFilterFactory {
  private defaultFilter = getDefaultFilter()

  public create(input?: string): ProductFilter {
    if (!input) return { ...this.defaultFilter }
    const query = JSON.parse(JSON.parse((input as unknown) as string))

    return {
      query: query.query || this.defaultFilter.query,
      priceRange: query.priceRange || this.defaultFilter.priceRange,
      categoryID: query.categoryID || this.defaultFilter.categoryID,
      isActive: this.getBooleanOrNull(query.isActive),
      isStockSync: this.getBooleanOrNull(query.isStockSync),
    }
  }

  private getBooleanOrNull(
    input: string | boolean | undefined
  ): boolean | null {
    return typeof input === 'boolean' ? input : null
  }
}
