import { injectable } from 'inversify'
import { GQLQuery } from '~/types/gql'
import { Repository } from '~/abstracts/repository'
import { ProductPropertiesType } from '~/features/product-properties/types'

@injectable()
export class ProductPropertiesRepository extends Repository {
  public async getProperties(): Promise<ProductPropertiesType> {
    const { data } = await this.apollo.client.query<{
      Countries: GQLQuery['Countries']
      Manufacturers: GQLQuery['Manufacturers']
      Ingredients: GQLQuery['Ingredients']
      Brands: GQLQuery['Brands']
    }>({
      query: await import('~/graphql/product_properties.graphql'),
    })

    return {
      countries: data.Countries.map(({ name }) => name),
      manufacturers: data.Manufacturers.map(({ name }) => name),
      ingredients: data.Ingredients.map(({ name }) => name),
      brands: data.Brands.map(({ name }) => name),
    }
  }
}
