import { createMask } from 'imask'

const VALID_INPUT_REGEXP = new RegExp(/7+\d{10}/)
const PHONE_REGEXP = new RegExp(/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/)
const MASK_PATTERN = '+7 (000) 000-00-00'

/**
 * Проверка полученного телефона на соответствие регулярному выражению
 * @param input {String}
 * @returns {Boolean}
 * @example
 * phoneInputValid('79261111111') // true
 * phoneInputValid('1-983-714-3070 x3027') // false
 */
export const phoneInputValid = (input: string): boolean => {
  return VALID_INPUT_REGEXP.test(input)
}

/**
 * Проверка телефона на соответствие регулярному выражению
 * @param input - телефон
 * @returns {Boolean}
 * @example
 * phoneValid('+7 (926) 111-11-11') // true
 * phoneValid('1-983-714-3070 x3027') // false
 */
export const phoneValid = (phone: string): boolean => {
  return PHONE_REGEXP.test(phone)
}

/**
 * Приведение кода к формату +7
 * @param phone
 * @returns телефон с форматированным кодом или исходную строку
 * @example
 * formatCountryCode('89261111111') // '+79261111111'
 * formatCountryCode('79261111111') // '+79261111111'
 * formatCountryCode('1-983-714-3070 x3027') // '1-983-714-3070 x3027'
 */
export const formatCountryCode = (phone: string): string =>
  phone.replace(/^8|^7/, '+7')

/**
 * Преобразование телефона в инпут
 * @param phone - пример: +7 (926) 111-11-11
 * @returns input форматированный инпут или исходную строку
 * @example
 * phoneToInput('+7 (926) 111-11-11') // '79261111111'
 * phoneToInput('1-983-714-3070 x3027') // '1-983-714-3070 x3027'
 */
export const phoneToInput = (phone: string): string => {
  if (!phoneValid(phone)) return phone
  return phone.replace(/\D+/g, '')
}

/**
 * Преобразование инпута в телефон для отображения
 * @param input {String}
 * @return телефон в формате +7 (926) 111-11-11 или инпут без изменения
 * @example
 * inputToPhone('79261111111') // '+7 (926) 111-11-11'
 * inputToPhone('1-983-714-3070 x3027') // '1-983-714-3070 x3027'
 */
export const inputToPhone = (input: string): string => {
  if (!phoneInputValid(input)) return input
  return getPhoneMasker().resolve(input)
}

export const getPhoneMasker = () => {
  return createMask({
    mask: MASK_PATTERN,
  })
}
