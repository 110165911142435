import { injectable } from 'inversify'
import { CategoryInputFactoryInterface } from '~/abstracts/factory'
import { CategoryFormType } from '~/features/category/types'
import { GQLCategorySaveInput } from '~/types/gql'

@injectable()
export class CategoryInputFactory implements CategoryInputFactoryInterface {
  create(form: CategoryFormType): GQLCategorySaveInput {
    const input: GQLCategorySaveInput = {
      name: form.name,
      slug: form.slug,
      isActive: form.isActive,
      onlinePayment: form.onlinePayment,
      description: form.description || undefined,
      seo: {
        title: form.seo.title,
        keywords: form.seo.keywords,
        description: form.seo.description,
      },
      filters: form.productFilters,
      flags: form.flags,
    }
    if (form.id) {
      input.id = String(form.id)
    }
    if (form.parentId) {
      input.parentId = String(form.parentId)
    }

    return input
  }
}
