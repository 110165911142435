import { Nullable } from '~/types'
import { GQLRole } from '~/types/gql'
import { ALLOWED_ROUTES_BY_ROLE, AppRoute, ROUTE_NAME } from '~/config/routers'

export const DEFAULT_ROUTE_PATH = '/products/1'

export const UNPROTECTED_ROUTE_NAMES = [ROUTE_NAME[AppRoute.Login]]

export const routeNameIsUnprotected = (name: Nullable<string>): boolean => {
  return !!name && UNPROTECTED_ROUTE_NAMES.includes(name as AppRoute)
}

export const redirectToLogin = (redirect: Function): void => {
  redirect({ name: ROUTE_NAME[AppRoute.Login] })
}

export const redirectToDefault = (redirect: Function): void => {
  redirect(DEFAULT_ROUTE_PATH)
}

export const getAppRouteByRouteName = (
  name: Nullable<string>
): AppRoute | null => {
  if (!name) return null

  const [appRoute] =
    Object.entries(ROUTE_NAME).find(([_, routeName]) => routeName === name) ??
    []
  return (appRoute as AppRoute) || null
}

export const allowedToEnter = (
  name: Nullable<string>,
  role: GQLRole
): boolean => {
  const appRoute = getAppRouteByRouteName(name)

  if (!appRoute) return false

  return !!ALLOWED_ROUTES_BY_ROLE.get(role)?.includes(appRoute)
}
