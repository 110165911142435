import { inject, injectable } from 'inversify'
import { ProductRepositoryInterface, Repository } from '~/abstracts/repository'
import DiTypes from '~/config/DiTypes'
import { Apollo } from '~/api/GraphqlClient'
import {
  GQLMutation,
  GQLProductSaveInput,
  GQLQuery,
  GQLQueryProductsArgs,
} from '~/types/gql'
import {
  ProductFormFactoryInterface,
  ProductListItemFactoryInterface,
} from '~/abstracts/factory'
import { ProductFormType } from '~/features/product/types'
import { ProductListItem } from '~/pages/products'
import { ValidationErrorFactory } from '~/app/validation-error'
import {
  ValidationError,
  ExpandedGraphQLError,
} from '~/app/validation-error/interface'
import { Nullable } from '~/types'

export interface ProductsResponse {
  totalItems: number
  totalPages: number
  products: ProductListItem[]
}

@injectable()
export class ProductRepository extends Repository
  implements ProductRepositoryInterface {
  private productFactory: ProductFormFactoryInterface
  private productListItemsFactory: ProductListItemFactoryInterface

  constructor(
    @inject(DiTypes.PRODUCT_FORM_FACTORY) factory: ProductFormFactoryInterface,
    @inject(DiTypes.PRODUCT_LIST_FACTORY)
    listItemFactory: ProductListItemFactoryInterface,
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo
  ) {
    super(apollo)
    this.productFactory = factory
    this.productListItemsFactory = listItemFactory
  }

  // Список продуктов для отображения на странице /products
  public getProducts = async ({
    page,
    first = 20,
    filter,
  }: GQLQueryProductsArgs): Promise<ProductsResponse> => {
    const { data } = await this.apollo.client.query<{
      Products: GQLQuery['Products']
    }>({
      query: await import('~/graphql/products.graphql'),
      variables: {
        page,
        first,
        filter,
      },
    })

    return {
      totalItems: data?.Products?.paginatorInfo.total ?? 0,
      totalPages: data?.Products?.paginatorInfo.count ?? 0,
      products: this.productListItemsFactory.fromArray(
        data?.Products?.data ?? []
      ),
    }
  }

  public getProductBySku = async (
    sku: string
  ): Promise<ProductFormType | null> => {
    const { data } = await this.apollo.client.query<{
      Products: GQLQuery['Products']
    }>({
      query: await import('~/graphql/product.graphql'),
      variables: {
        limit: 1,
        page: 1,
        filter: {
          sku,
        },
      },
    })

    if (!data?.Products) {
      return null
    }

    const [productEdges] = data.Products.data
    return productEdges ? this.productFactory.create(productEdges) : null
  }

  public deleteProduct = async (productID: number): Promise<boolean> => {
    const { data } = await this.apollo.client.mutate<{
      ProductRemove: GQLMutation['ProductRemove']
    }>({
      mutation: await import('~/graphql/product_remove.graphql'),
      variables: {
        productID,
      },
    })
    return Boolean(data?.ProductRemove)
  }

  public saveProduct = async (
    input: GQLProductSaveInput
  ): Promise<{
    product: Nullable<ProductFormType>
    errors: ValidationError[]
  }> => {
    const { data, errors } = await this.apollo.client.mutate<{
      ProductSave: GQLMutation['ProductSave']
    }>({
      mutation: await import('~/graphql/product_save.graphql'),
      variables: {
        input,
      },
    })

    return {
      product: data?.ProductSave
        ? this.productFactory.create(data.ProductSave)
        : null,
      errors: errors?.[0]
        ? ValidationErrorFactory.create(errors[0] as ExpandedGraphQLError)
        : [],
    }
  }
}
