import { injectable } from 'inversify'
import { TreeSelectNode } from '~/features/category-tree-select/tree-select-node'
import { GQLCategoryTree } from '~/types/gql'
import { TreeSelectNodeFactoryInterface } from '~/features/category-tree-select/types'

@injectable()
export class TreeSelectNodeFactory implements TreeSelectNodeFactoryInterface {
  create(
    categories: GQLCategoryTree[],
    excludeIds: number[] = []
  ): TreeSelectNode[] {
    const flatTree = categories.map((category) => {
      const isDisabled = excludeIds.includes(Number(category.id))
      return this.createNode(category, isDisabled)
    })

    return this.flatToTree(flatTree)
  }

  private createNode(
    category: GQLCategoryTree,
    exclude: boolean
  ): TreeSelectNode {
    return {
      id: Number(category.id),
      label: category.name,
      parentId: category.parent ? Number(category.parent.id) : undefined,
      isDisabled: exclude,
      children: undefined,
    }
  }

  private flatToTree(categories: TreeSelectNode[]): TreeSelectNode[] {
    const roots: TreeSelectNode[] = []
    const map: { [key: string]: number } = {}

    for (let i = 0; i < categories.length; i += 1) {
      map[categories[i].id] = i
    }

    for (let i = 0; i < categories.length; i += 1) {
      const node: TreeSelectNode = categories[i]
      if (node.parentId) {
        if (categories[map[node.parentId]].children === undefined) {
          categories[map[node.parentId]].children = []
        }
        // @ts-ignore
        categories[map[node.parentId]].children.push(node)
      } else {
        roots.push(node)
      }
    }
    return roots
  }
}
