import { Factory, OrderListFactoryInterface } from '~/abstracts/factory'
import { GQLOrder } from '~/types/gql'
import { OrderListItem } from '~/pages/orders'
import { centsToPrice } from '~/utils/format-price'

export class OrderListFactory extends Factory<GQLOrder, OrderListItem>
  implements OrderListFactoryInterface {
  public create(input: GQLOrder): OrderListItem {
    return {
      id: input.id,
      orderNumber: input.orderNumber,
      amount: centsToPrice(input.amount),
      date: input.createdAt,
      status: input.status.code,
      customer: input.customer.name,
      customerPhone: input.customer.phone,
    }
  }
}
