import { GQLRole } from '~/types/gql'

export enum AppRoute {
  Index = 'Index',
  Login = 'Login',
  Product = 'Product',
  ProductCreate = 'ProductCreate',
  Products = 'Products',
  Order = 'Order',
  Orders = 'Orders',
  Category = 'Category',
  CategoryCreate = 'CategoryCreate',
  Categories = 'Categories',
  UserReport = 'UserReport',
  Managers = 'Managers',
  ManagerCreate = 'ManagerCreate',
  Manager = 'Manager',
  ReportManagerLogs = 'ReportManagerLogs',
  SearchHistory = 'SearchHistory',
  MainPageManager = 'MainPageManager',
  Brands = 'Brands',
  BrandCreate = 'BrandCreate',
  Brand = 'Brand',
}

export const ROUTE_NAME: Record<AppRoute, string> = {
  [AppRoute.Index]: 'index',
  [AppRoute.Login]: 'login',
  [AppRoute.Product]: 'product-sku',
  [AppRoute.ProductCreate]: 'product-create',
  [AppRoute.Products]: 'products-page',
  [AppRoute.Order]: 'order-id',
  [AppRoute.Orders]: 'orders',
  [AppRoute.Category]: 'category-id',
  [AppRoute.CategoryCreate]: 'category-create',
  [AppRoute.Categories]: 'categories',
  [AppRoute.UserReport]: 'report',
  [AppRoute.Managers]: 'managers',
  [AppRoute.ManagerCreate]: 'manager-create',
  [AppRoute.Manager]: 'manager-id',
  [AppRoute.ReportManagerLogs]: 'report-manager-logs',
  [AppRoute.SearchHistory]: 'search-history',
  [AppRoute.MainPageManager]: 'page-manager-main',
  [AppRoute.Brands]: 'brands',
  [AppRoute.Brand]: 'brand-slug',
  [AppRoute.BrandCreate]: 'brand-create',
}

export const ORDER_MANAGER_ALLOWED: AppRoute[] = [
  AppRoute.Product,
  AppRoute.ProductCreate,
  AppRoute.Products,
  AppRoute.Order,
  AppRoute.Orders,
  AppRoute.Category,
  AppRoute.CategoryCreate,
  AppRoute.Categories,
]

export const CONTENT_MANAGER_ALLOWED: AppRoute[] = [
  AppRoute.Product,
  AppRoute.ProductCreate,
  AppRoute.Products,
  AppRoute.Category,
  AppRoute.CategoryCreate,
  AppRoute.Categories,
  AppRoute.SearchHistory,
  AppRoute.MainPageManager,
  AppRoute.ReportManagerLogs,
  AppRoute.Brands,
]

export const ALLOWED_ROUTES_BY_ROLE: Map<GQLRole, AppRoute[]> = new Map([
  [GQLRole.Admin, Object.values(AppRoute)],
  [GQLRole.ContentManager, CONTENT_MANAGER_ALLOWED],
  [GQLRole.OrderManager, ORDER_MANAGER_ALLOWED],
])
