import { inject } from 'inversify'
import { CategoryRepositoryInterface, Repository } from '~/abstracts/repository'
import {
  GQLCategoryFilter,
  GQLCategorySaveInput,
  GQLCategoryTree,
  GQLCategoryTreeSaveInput,
  GQLMutation,
  GQLQuery,
} from '~/types/gql'
import DiTypes from '~/config/DiTypes'
import { Apollo } from '~/api/GraphqlClient'
import { CategoryFormType } from '~/features/category/types'
import {
  CategoryFormFactoryInterface,
  CategoryListItemFactoryInterface,
  CategoryProductFactoryInterface,
  CategoryTreeFactoryInterface,
} from '~/abstracts/factory'
import { CategoryListItem } from '~/pages/categories'
import { Nullable } from '~/types'
import {
  ExpandedGraphQLError,
  ValidationError,
} from '~/app/validation-error/interface'
import { ValidationErrorFactory } from '~/app/validation-error'
import { CategoryProduct } from '~/features/category-product'

export class CategoryRepository extends Repository
  implements CategoryRepositoryInterface {
  private categoryListItemFactory: CategoryListItemFactoryInterface
  private categoryTreeFactory: CategoryTreeFactoryInterface
  private categoryFactory: CategoryFormFactoryInterface
  private readonly categoryProductFactory: CategoryProductFactoryInterface

  constructor(
    @inject(DiTypes.CATEGORY_LIST_ITEM_FACTORY)
    listItemFactory: CategoryListItemFactoryInterface,
    @inject(DiTypes.CATEGORY_TREE_FACTORY)
    categoryTreeFactory: CategoryTreeFactoryInterface,
    @inject(DiTypes.CATEGORY_FORM_FACTORY)
    categoryFactory: CategoryFormFactoryInterface,
    @inject(DiTypes.CATEGORY_PRODUCT_FACTORY)
    categoryProductFactory: CategoryProductFactoryInterface,
    @inject(DiTypes.GRAPHQL_CLIENT) apollo: Apollo
  ) {
    super(apollo)
    this.categoryListItemFactory = listItemFactory
    this.categoryFactory = categoryFactory
    this.categoryTreeFactory = categoryTreeFactory
    this.categoryProductFactory = categoryProductFactory
  }

  public async getCategories(
    withAncestors: boolean,
    filter?: GQLCategoryFilter
  ): Promise<CategoryListItem[]> {
    let graphqlQuery = await import('~/graphql/categories.graphql')
    if (withAncestors) {
      graphqlQuery = await import('~/graphql/categories_with_ancestors.graphql')
    }

    const { data } = await this.apollo.client.query<{
      Categories: GQLQuery['Categories']
    }>({
      query: graphqlQuery,
      variables: {
        filter,
      },
    })
    return this.categoryListItemFactory.createWithAncestorsFromArray(
      data.Categories
    )
  }

  public async getCategoriesTree(): Promise<GQLCategoryTree[]> {
    const { data } = await this.apollo.client.query<{
      Categories: GQLQuery['Categories']
    }>({
      query: await import('~/graphql/categories.graphql'),
    })
    return data.Categories
  }

  public async saveCategoriesTree(
    input: GQLCategoryTreeSaveInput[]
  ): Promise<Boolean> {
    const { data } = await this.apollo.client.mutate<{
      CategoryTreeSave: GQLMutation['CategoryTreeSave']
    }>({
      mutation: await import('~/graphql/category_tree_save.graphql'),
      variables: { input },
    })

    return Boolean(data?.CategoryTreeSave)
  }

  public async getCategoryById(id: string): Promise<CategoryFormType> {
    const { data } = await this.apollo.client.query<{
      Category: GQLQuery['Category']
    }>({
      query: await import('~/graphql/category.graphql'),
      variables: {
        id,
      },
    })
    if (!data?.Category) {
      throw new Error('Category was not found')
    }
    return this.categoryFactory.create(data.Category)
  }

  public async saveCategory(
    input: GQLCategorySaveInput
  ): Promise<{
    category: Nullable<CategoryFormType>
    errors: ValidationError[]
  }> {
    const { data, errors } = await this.apollo.client.mutate<{
      CategorySave: GQLMutation['CategorySave']
    }>({
      mutation: await import('~/graphql/category_save.graphql'),
      variables: { input },
    })

    return {
      category: data?.CategorySave
        ? this.categoryFactory.create(data?.CategorySave)
        : null,
      errors: errors?.[0]
        ? ValidationErrorFactory.create(errors[0] as ExpandedGraphQLError)
        : [],
    }
  }

  public async deleteCategory(categoryID: number): Promise<boolean> {
    const { data } = await this.apollo.client.mutate<{
      CategoryRemove: GQLMutation['CategoryRemove']
    }>({
      mutation: await import('~/graphql/category_delete.graphql'),
      variables: { categoryID },
    })

    return Boolean(data?.CategoryRemove)
  }

  public async getCategoryProducts(
    categoryID: number
  ): Promise<CategoryProduct[]> {
    const { data } = await this.apollo.client.query<
      {
        Products: GQLQuery['Products']
      },
      { first: number; page: number; categoryID: number }
    >({
      query: await import('~/graphql/category/category_products.graphql'),
      variables: {
        first: 5,
        page: 1,
        categoryID,
      },
    })

    return this.categoryProductFactory.fromArray(data.Products.data)
  }
}
