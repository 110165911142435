import {
  GQLSearchQueryGroupedResult,
  GQLSearchQueryTimelineResult,
} from '~/types/gql'
import {
  SearchQueryGrouped,
  SearchQueryTimeline,
} from '~/features/search/search-query/search-query.interface'

export const searchQueryGroupedMapper = (
  input: GQLSearchQueryGroupedResult
): SearchQueryGrouped => {
  return {
    query: input.query,
    lastRequest: input.lastRequest,
    total: input.total,
  }
}

export const searchQueryTimelineMapper = (
  input: GQLSearchQueryTimelineResult
): SearchQueryTimeline => {
  return {
    query: input.query,
    createdAt: input.createdAt,
  }
}
